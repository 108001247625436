import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Verifyemail from "./components/popup/Verifyemail";
import LandingPage from "./components/Landing-page/LandingPage";
import Welcome from "./components/Authentication/Welcome";
import Terms from "./components/Authentication/Terms";
import Privacy from "./components/Authentication/Privacy";
import UserInterestPopup from "./components/LoginSignup/UserIntrestPopup";
import RecommondedPeople from "./components/LoginSignup/RecommondedPeople";
import StreamHomepage from "./components/Bystream/StreamHomePage";
import RecommondationsPage from "./components/Bystream/RecommondationsPage";
import WriteUps from "./components/Bystream/WriteUps";
import NotesPage from "./components/Bystream/NotesPage";
import StreaRecommendedPeople from "./components/Bystream/StreaRecommendedPeople";
import Home from "./components/Home/Home";
import AllSpotlight from "./components/Home/AllSpotlight";
import AllUpdatedContent from "./components/Home/AllUpdatedContent";
import AllPopularGenres from "./components/Home/AllPopularGenres";
import PopularGenres from "./components/Home/PopularGenres";
import NavBar from "./components/common/NavBar";
import PostAsViewByUser from "./components/Home/PostAsViewByUser";
import Helps from "./components/setting-help/Helps";
import PrivacyTerms from "./components/setting-help/PrivacyTerms";
import HelpPrivacyMain from "./components/HelpSettings/HelpPrivacyMain";
import Notifications from "./components/setting-help/Notifications";
import Accountsetting from "./components/setting-help/Accountsetting";
import PersonliseComman from "./components/LoginSignup/PersonliseComman";
import Error from "./View/Error";
import Allcreator from "./components/Bystream/Allcreator";
import QuestionPaper from "./components/Bystream/QuestionPaper";
import PrivacyandSecurity from "./components/setting-help/PrivacyandSecurity";
import HomeProfile from "./components/Profile/HomeProfile";
import PostAsViewByReader from "./components/Profile/PostAsViewByReader";
import CreatePost from "./components/Create/CreatePost";
import SideBar from "./components/setting-help/SideBar";
import CheckConnection from "./components/CheckOffline";
import ShowPdf from "./components/Bystream/ShowPdf";
import MyInput from "./components/Create/MyInput";
import PrivateRoute from "./components/Authentication/PrivateRoute";
import ShowPdf2 from "./components/Bystream/ShowPdf2";
import { UseAuth } from "./components/ContextApis/AuthContext";
import { getToken } from "./components/ContextApis/TokenStore";
import AllExams from "./components/Bystream/AllExams";

function App() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const location = useLocation();
  const navigate = useNavigate();
  const pathArray = [
    "/university",
    "/streamrecommondations",
    "/streamnotespage",
    "/streamwriteups",
    "/streamrecommendedpeople",
    "/home",
    "/home/allupdatedcontent",
    "/home/allspotlight",
    `/home/PostAsViewByUser`,
    "/home/populargenres",
    "/home/allpopulargenres",
    "/Allcreator/",
    "/questionpaper",
    "/profile",
    "/ViewByUserprofile",
    "/userPost",
    "/settings",
    "/Allcreator",
    "/exams",
  ];

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  const { handleTokenRefresh } = UseAuth();

  handleTokenRefresh();

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    scrollToTop();
    let contentBottomSpace = document.querySelector(".all_content");
    if (pathArray.includes(location.pathname) && window.innerWidth < 1024) {
      contentBottomSpace.classList.add("mb-16");
    } else {
      contentBottomSpace.classList.remove("mb-16");
    }
  }, [location.pathname, windowWidth]);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [username, setusername] = useState(
    localStorage.getItem("userName") || sessionStorage.getItem("userName")
  );

  useEffect(() => {
    if (username) {
      if (location.pathname === "/login" || location.pathname === "/") {
        navigate("/home");
      }
    }
    if (!username && !getToken()) {
      if (location.pathname !== "/login" && location.pathname !== "/" && !location.pathname.startsWith("/university")) {
        navigate("/login");
      }
    }
    if (!username && getToken()) {
      navigate("/personlise");
    }
    if (username ==="null" || username === null) {
      if (location.pathname !== "/login" && location.pathname !== "/") {
        navigate("/login");
      }
    }
    if (localStorage.getItem("tokens") !== null) {
      sessionStorage.clear();
    }
  }, []);

  return (
    <>
      <div className="flex flex-col">
        <div className="sticky top-0 z-[500000000]">
          {(pathArray.includes(location.pathname) ||
            location.pathname.startsWith("/home") ||
            location.pathname.startsWith("/ViewByUserprofile") ||
            location.pathname.startsWith("/Allcreator") ||
            location.pathname.startsWith("/settings") ||
            location.pathname.startsWith("/university")) && <NavBar />}
        </div>
        <div className="all_content">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/login" element={<Welcome />} />
            <Route path="/verification" element={<Verifyemail />} />
            <Route path="/privacypolicy" element={<Privacy />} />
            <Route path="/landingpage" element={<LandingPage />} />
            <Route path="/university/:id?" element={<StreamHomepage />} />
            <Route path="/recommendedpeople" element={<RecommondedPeople />} />
            <Route path="/interesteduser" element={<UserInterestPopup />} />
            <Route path="/personlise" element={<PersonliseComman />} />
            <Route path="/streamrecommondations" element={<RecommondationsPage />} />
            <Route path="/Allcreator/:category" element={<Allcreator />} />
            <Route path="/streamnotespage" element={<NotesPage />} />
            <Route path="/streamwriteups" element={<WriteUps />} />
            <Route path="/exams" element={<AllExams />} />
            <Route path="/questionpaper" element={<QuestionPaper />} />
            <Route path="/showpdf2/:id" element={<ShowPdf2 />} />
            <Route path="/streamrecommendedpeople" element={<StreaRecommendedPeople />} />
            <Route path="/home" element={<Home />} />
            <Route path="/home/allspotlight" element={<AllSpotlight />} />
            <Route path="/home/allupdatedcontent" element={<AllUpdatedContent />} />
            <Route path="/home/allpopulargenres" element={<AllPopularGenres />} />
            <Route path="/home/populargenres/:category" element={<PopularGenres />} />
            <Route path="/home/PostAsViewByUser/:id" element={<PostAsViewByUser />} />
            <Route path="/showpdf/:id" element={<ShowPdf />} />
            <Route path="/profile" element={<PrivateRoute Component={HomeProfile} />} />
            <Route
              path="/ViewByUserprofile/:username"
              element={<PrivateRoute Component={HomeProfile} />}
            />
            <Route path="/userPost" element={<PrivateRoute Component={PostAsViewByReader} />} />
            {windowWidth > 640 ? (
              <Route path="settings" element={<SideBar />}>
                <Route path="accounts-settings" element={<Accountsetting />} />
                <Route path="privacy-security" element={<PrivacyandSecurity />} />
                <Route path="privacy-security/privacy-terms" element={<PrivacyTerms />} />
                <Route path="notifications-settings" element={<Notifications />} />
                <Route path="helps" element={<Helps />} />
                <Route path="" element={<HelpPrivacyMain />} />
              </Route>
            ) : (
              <Route path="settings" element={<SideBar />}></Route>
            )}
            <Route path="/settings/accounts-settings" element={<Accountsetting />} />
            <Route path="/settings/privacy-security" element={<PrivacyandSecurity />} />
            <Route path="/settings/notifications-settings" element={<Notifications />} />
            <Route path="/settings/helps" element={<Helps />} />
            <Route path="/settings/privacy-security/privacy-terms" element={<PrivacyTerms />} />
            <Route path="create-post" element={<CreatePost />} />
            <Route path="tt" element={<MyInput />} />
            <Route path="*" element={<Error />} />
          </Routes>
          <CheckConnection />
        </div>
      </div>
    </>
  );
}

export default App;
