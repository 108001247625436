import React from "react";
import { CrossIcon } from "../common/Icons";
import Btn from "../popup/Btn";
import { useNavigate } from "react-router-dom";
import { UseAuth } from "../ContextApis/AuthContext";
import { signOut } from "firebase/auth";
import { auth } from "../ContextApis/FirebaseConfig";

function LogoutPopup({ setLogoutPopup }) {
  const navigate = useNavigate();

  const { logout } = UseAuth(); // Get the logout function from AuthContext

  async function handleLogout() {
    sessionStorage.clear();
    localStorage.clear();
    navigate("/login");
    await logout();
    await signOut(auth).then(() => {
      console.log("logout success");
    });
    setLogoutPopup(false);
  }

  return (
    <div className="backdrop-blur-[10px] z-[600] fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] h-[100vh] w-full max-sm:overflow-auto">
      <div className="w-full h-[100vh] flex justify-center items-center px-3">
        <div className="flex justify-end flex-col lg:h-[240px] bg-white rounded-[18px] md:rounded-[40px] h-[200px] w-[97%] sm:w-[400px] lg:w-[620px] pt-2 pb-[22px] relative shadow-[0_8px_20px_0_rgba(0,0,0,0.2)]">
          <button
            onClick={() => setLogoutPopup(false)}
            className="max-sm:hidden absolute right-5 top-3">
            <CrossIcon />
          </button>
          <p className="font-normal text-sm lg:text-md leading-6 text-black text-center mt-9 w-[85%] mx-auto">
            Are you sure you want to logout?
          </p>
          <div className="flex items-center gap-[40px] sm:gap-[64px] mt-[42px] justify-center">
            <button onClick={handleLogout}>
              <Btn btnname={"Logout"} />
            </button>
            <button onClick={() => setLogoutPopup(false)}>
              <Btn btnname={"Cancel"} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LogoutPopup;
