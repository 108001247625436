import React, { createContext, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import showToast from "../ToastSucess";
import showErrorToast from "../ToastError";
import Loader from "../Loader";
import { getToken, getTokenTime } from "./TokenStore";
import { path } from "../common/Api";
import {
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  GithubAuthProvider,
} from "firebase/auth";
import { auth } from "./FirebaseConfig";

const AuthContext = createContext();
let tokens = null;
let localTokenStore = JSON.parse(localStorage.getItem("tokens"));
let sessionTokenStore = JSON.parse(sessionStorage.getItem("tokens"));
if (localTokenStore === null) {
  tokens = sessionTokenStore;
} else {
  tokens = localTokenStore;
}

const provider = new GoogleAuthProvider();
const fbProvider = new FacebookAuthProvider();
const githubProvider = new GithubAuthProvider();

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [activeStepsLogin, setactiveStepsLogin] = useState("");
  const navigate = useNavigate();

  // Forgot password
  const forgotPassword = async (email, setVerifyOtp, setForgotPassword) => {
    setLoading(true);
    try {
      const response = await fetch(`${path}auth/password/forgot-password`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      });

      const errorData = await response.json();
      if (!response.ok) {
        showErrorToast(errorData.message);
        throw new Error(errorData.message || "Failed to send Otp");
      } else {
        setVerifyOtp(true);
        setForgotPassword(false);
        showToast(errorData.message);
      }
    } catch (err) {
      console.error("error:", err);
      console.error("Error details:", err.message, err.stack);
    } finally {
      setLoading(false);
    }
  };

  // Generate otp
  const generateOtp = async (email, setVerifyOtpPopup, setSignUpPopup) => {
    setLoading(true);
    try {
      const otpResponse = await fetch(`${path}otp/generateOtp/${email}`);
      const data = await otpResponse.json();
      if (otpResponse.ok) {
        setSignUpPopup(false);
        setVerifyOtpPopup(true);
        showToast(data.message);
      } else {
        showErrorToast(data.error);
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  // Verify forgot otp
  const verifyForgotOtp = async (otp, setVerifyOtpPopup, setPersonlisePopup, email) => {
    setLoading(true);
    try {
      let data = {
        email: email,
        otp: otp,
      };
      const validateOtpResponse = await fetch(`${path}otp/validateOtp`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });
      const responseData = await validateOtpResponse.json();
      if (validateOtpResponse.ok) {
        showToast(responseData.message);
        setVerifyOtpPopup(false);
        setPersonlisePopup(true);
      } else {
        showErrorToast(responseData.error);
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  // Verfy otp
  const verifyOtp = async (otp, setVerifyOtpPopup, setPersonlisePopup, email) => {
    setLoading(true);
    let storedEmail = JSON.parse(sessionStorage.getItem("user_register_details"));

    try {
      let data = {
        email: storedEmail === null ? email : storedEmail.email,
        otp: otp,
      };
      const validateOtpResponse = await fetch(`${path}otp/validateOtp`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });
      const responseData = await validateOtpResponse.json();
      if (validateOtpResponse.ok) {
        showToast(responseData.message);
        setVerifyOtpPopup(false);
        setPersonlisePopup(true);
        if (email === "register") {
          let registerData = {
            fullName: storedEmail.fullName,
            email: storedEmail.email,
            password: storedEmail.password,
            confirmpassword: storedEmail.confirmpassword,
          };
          try {
            const response = await fetch(`${path}auth/register`, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(registerData),
            });
            const data = await response.json();

            const tokens = {
              refreshToken: data.refresh_token,
              accessToken: data.access_token,
            };
            if (!response.ok) {
              const errorData = await response.json();
              throw new Error(errorData.message || "Failed to register");
            } else {
              localStorage.setItem("tokens", JSON.stringify(tokens));
              localStorage.setItem("login", true);
            }
          } catch (err) {
            console.log("Error", err);
          }
        }
      } else {
        showErrorToast("Invaild OTP");
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  // Update forgot password
  const updateForgotPassword = async (email, password, conifpassword, setNewPassword) => {
    setLoading(true);
    try {
      let data = {
        email: email,
        newPassword: password,
        newPasswordConfirm: conifpassword,
      };
      const validateOtpResponse = await fetch(`${path}auth/password/forgot-reset-password`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });
      const responseData = await validateOtpResponse.json();
      if (validateOtpResponse.ok) {
        showToast(responseData.message);
        setNewPassword(false);
      } else {
        showErrorToast(responseData.message);
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  // Login
  const userLogin = async (email, password, rememberMe, setSignInPopup) => {
    setLoading(true);
    try {
      const response = await fetch(`${path}auth/authenticate`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        showErrorToast(errorData.error);
        throw new Error(errorData.message || "Failed to authenticate");
      } else {
        setSignInPopup(false);
        const data = await response.json();

        const tokens = {
          refreshToken: data.refresh_token,
          accessToken: data.access_token,
        };
        const tokensValidity = {
          refreshTokenExpiry: data.refreshTokenExpiry,
          accessTokenExpiry: data.accessTokenExpiry,
        };
        localStorage.setItem("tokens", JSON.stringify(tokens));
        localStorage.setItem("tokensExpireTime", JSON.stringify(tokensValidity));
        localStorage.setItem("login", true);

        try {
          const requestOptions = {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${data.access_token}`,
            },
          };
          const response = await fetch(`${path}user/profile`, requestOptions);
          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || "Failed to fetch profile data");
          } else {
            setLoading(false);
            const data = await response.json();
            localStorage.setItem("userName", data.data.userName);
            let path = localStorage.getItem("sharePath") || sessionStorage.getItem("sharePath");

            if (data.data.userName !== null) {
              if (path === null) {
                navigate("/home");
              } else {
                navigate(path);
                localStorage.removeItem("sharePath");
                sessionStorage.clear();
              }
              setactiveStepsLogin("");
              window.location.reload();
            } else {
              navigate("/personlise");
              setactiveStepsLogin(1);
            }
          }
        } catch (err) {
          console.log("Error :", err);
        }
      }
    } catch (err) {
      console.error("Login failed:", err);
      console.error("Error details:", err.message, err.stack);
    } finally {
      setLoading(false);
    }
  };

  // Continue as guest
  // const continueAsGuest = async () => {
  //   try {
  //     const response = await fetch(`${path}auth/guest`, {
  //       method: "POST",
  //     });
  //     const data = await response.json();
  //     const tokens = {
  //       refreshToken: data.refresh_token,
  //       accessToken: data.access_token,
  //     };
  //     if (response.ok) {
  //       sessionStorage.setItem("tokens", JSON.stringify(tokens));
  //       sessionStorage.setItem("userName", "Guest");
  //       navigate("/home");
  //       window.location.reload();
  //     }
  //   } catch (error) {
  //     console.error("Error during logout:", error);
  //   }
  // };


  const continueAsGuest = async () => {
    try {
      const response = await fetch(`${path}auth/guest`, {
        method: "POST",
      });
      const data = await response.json();
      const tokens = {
        refreshToken: data.refresh_token,
        accessToken: data.access_token,
      };
      if (response.ok) {
        localStorage.setItem("tokens", JSON.stringify(tokens)); // Changed from sessionStorage to localStorage
        localStorage.setItem("userName", "Guest");
        navigate("/home");
        window.location.reload();
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  // Logout
  const logout = async () => {
    try {
      const response = await fetch(`${path}auth/logout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokens.accessToken}`,
        },
      });

      const data = await response.json();
      if (response.ok) {
        navigate("/login");
        localStorage.clear();
        sessionStorage.clear();
      } else {
        console.error("Logout failed:", data.message);
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const isTokenExpired = () => {
    const tokenTime = getTokenTime();
    const expiryTime = new Date(tokenTime?.accessTokenExpiry).getTime();
    const currentTime = new Date().getTime();
    const timeBeforeExpiry = expiryTime - 120000;
    return currentTime >= timeBeforeExpiry;
  };

  // Refresh token
  const refreshTokens = async () => {
    try {
      let tokens = getToken();

      const tokenTime = getTokenTime();
      const expiryTime = new Date(tokenTime?.refreshTokenExpiry).getTime();
      const currentTime = new Date().getTime();

      if (currentTime >= expiryTime) {
        localStorage.clear();
        navigate("/login");
        return;
      }

      const response = await fetch(`${path}auth/refresh-token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokens.refreshToken}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to refresh token");
      }

      const data = await response.json();
      const tokenss = {
        refreshToken: data.refresh_token,
        accessToken: data.access_token,
      };
      const tokensValidity = {
        refreshTokenExpiry: data.refreshTokenExpiry,
        accessTokenExpiry: data.accessTokenExpiry,
      };
      localStorage.setItem("tokens", JSON.stringify(tokenss));
      localStorage.setItem("tokensExpireTime", JSON.stringify(tokensValidity));
      window.location.reload();
    } catch (error) {
      console.error("Error refreshing token:", error);
      // Optionally redirect to login
    } finally {
      setLoading(false);
    }
  };

  const handleTokenRefresh = async () => {
    if (isTokenExpired()) {
      // await refreshTokens();
      localStorage.clear();
      sessionStorage.clear();
      navigate("/login");
    }
  };

  async function signUpWithGoogle() {
    let credential = await signInWithPopup(auth, provider).then((result) => {
      return GoogleAuthProvider.credentialFromResult(result).idToken;
    });
    try {
      localStorage.clear();
      const response = await fetch(`${path}auth/authenticate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${credential}`,
        },
      });
      const data = await response.json();
      const tokens = {
        refreshToken: data.refresh_token,
        accessToken: data.access_token,
      };
      const tokensValidity = {
        refreshTokenExpiry: data.refreshTokenExpiry,
        accessTokenExpiry: data.accessTokenExpiry,
      };
      localStorage.setItem("tokens", JSON.stringify(tokens));
      localStorage.setItem("tokensExpireTime", JSON.stringify(tokensValidity));
      localStorage.setItem("login", true);
      if (response.ok) {
        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        };
        const response = await fetch(`${path}user/profile`, requestOptions);
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Failed to fetch profile data");
        } else {
          setLoading(false);
          const data = await response.json();
          localStorage.setItem("userName", data.data.userName);
          let path = localStorage.getItem("sharePath") || sessionStorage.getItem("sharePath");
          if (data.data.userName !== null) {
            if (path === null) {
              navigate("/home");
            } else {
              navigate(path);
              localStorage.removeItem("sharePath");
              sessionStorage.clear();
            }
            setactiveStepsLogin("");
            window.location.reload();
          } else {
            navigate("/personlise");
          }
        }
      }
    } catch (err) {
      console.error("Login failed:", err);
      console.error("Error details:", err.message, err.stack);
    }
  }
  async function signUpWithFacebook() {
    let credential = await signInWithPopup(auth, fbProvider).then((result) => {
      return FacebookAuthProvider.credentialFromResult(result).accessToken;
    });
    try {
      localStorage.clear();
      const response = await fetch(`${path}auth/authenticate/facebook`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${credential}`,
        },
      });
      const data = await response.json();
      const tokens = {
        refreshToken: data.refresh_token,
        accessToken: data.access_token,
      };
      const tokensValidity = {
        refreshTokenExpiry: data.refreshTokenExpiry,
        accessTokenExpiry: data.accessTokenExpiry,
      };
      localStorage.setItem("tokens", JSON.stringify(tokens));
      localStorage.setItem("tokensExpireTime", JSON.stringify(tokensValidity));
      localStorage.setItem("login", true);
      if (response.ok) {
        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        };
        const response = await fetch(`${path}user/profile`, requestOptions);
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Failed to fetch profile data");
        } else {
          setLoading(false);
          const data = await response.json();
          localStorage.setItem("userName", data.data.userName);
          let path = localStorage.getItem("sharePath") || sessionStorage.getItem("sharePath");
          if (data.data.userName !== null) {
            if (path === null) {
              navigate("/home");
            } else {
              navigate(path);
              localStorage.removeItem("sharePath");
              sessionStorage.clear();
            }
            setactiveStepsLogin("");
            window.location.reload();
          } else {
            navigate("/personlise");
          }
        }
      }
    } catch (err) {
      console.error("Login failed:", err);
      console.error("Error details:", err.message, err.stack);
    }
  }
  async function signUpWithGithub() {
    githubProvider.addScope("user:email");
    let credential = await signInWithPopup(auth, githubProvider).then((result) => {
      return GithubAuthProvider.credentialFromResult(result).accessToken;
    });
    try {
      localStorage.clear();
      const response = await fetch(`${path}auth/authenticate/github`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${credential}`,
        },
      });
      const data = await response.json();
      const tokens = {
        refreshToken: data.refresh_token,
        accessToken: data.access_token,
      };
      const tokensValidity = {
        refreshTokenExpiry: data.refreshTokenExpiry,
        accessTokenExpiry: data.accessTokenExpiry,
      };
      localStorage.setItem("tokens", JSON.stringify(tokens));
      localStorage.setItem("tokensExpireTime", JSON.stringify(tokensValidity));
      localStorage.setItem("login", true);
      if (response.ok) {
        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        };
        const response = await fetch(`${path}user/profile`, requestOptions);
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Failed to fetch profile data");
        } else {
          setLoading(false);
          const data = await response.json();
          localStorage.setItem("userName", data.data.userName);
          let path = localStorage.getItem("sharePath") || sessionStorage.getItem("sharePath");
          if (data.data.userName !== null) {
            if (path === null) {
              navigate("/home");
            } else {
              navigate(path);
              localStorage.removeItem("sharePath");
              sessionStorage.clear();
            }
            setactiveStepsLogin("");
            window.location.reload();
          } else {
            navigate("/personlise");
          }
        }
      }
    } catch (err) {
      console.error("Login failed:", err);
      console.error("Error details:", err.message, err.stack);
    }
  }

  return (
    <AuthContext.Provider
      value={{
        loading,
        handleTokenRefresh,
        generateOtp,
        userLogin,
        logout,
        continueAsGuest,
        forgotPassword,
        activeStepsLogin,
        setactiveStepsLogin,
        verifyOtp,
        refreshTokens,
        updateForgotPassword,
        verifyForgotOtp,
        signUpWithGoogle,
        signUpWithFacebook,
        signUpWithGithub,
      }}>
      {loading && <Loader />}
      {children}
    </AuthContext.Provider>
  );
};

export const UseAuth = () => {
  return useContext(AuthContext);
};
