// import React, { useEffect, useState } from "react";
// import ReactQuill, { Quill } from "react-quill";
// import "react-quill/dist/quill.snow.css";

// const Texteditor = ({ message, setContent }) => {
//   const [value, setValue] = useState(message)

//   const handleChange = (content, delta, source, editor) => {
//     setValue(editor.getHTML());
//     setContent(editor.getHTML());
//   };

//   useEffect(() => {
//     const Clipboard = Quill.import("modules/clipboard");

//     class CustomClipboard extends Clipboard {
//       onPaste(e) {
//         e.preventDefault();
//         const clipboardData = e.clipboardData || window.clipboardData;
//         const text = clipboardData.getData("text/plain");
//         const html = clipboardData.getData("text/html");

//         const content = html
//           ? html
//           : text.replace(/\n/g, "<br>"); // Preserve line breaks and spacing

//         const delta = this.quill.clipboard.convert(content);
//         this.quill.setContents(delta, "silent");
//       }
//     }

//     Quill.register("modules/clipboard", CustomClipboard, true);
//   }, []);

//   return (
//     <div className="texteditor-container sm:h-[340px] xl:h-[515px] overflow-x-hidden">
//       <ReactQuill
//         value={value}
        
//         onChange={handleChange}
//         theme="snow"
//         modules={modules}
//         formats={formats}
//         placeholder="Write something..."
//       />
//     </div>
//   );
// };

// const modules = {
//   toolbar: [
//     ["bold", "italic", "underline"],
//     ["link", "image"],
//   ],
// };

// const formats = ["bold", "italic", "underline", "link", "image"];

// export default Texteditor;





import React, { useState, useEffect } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

const Texteditor = ({ message, setContent }) => {
  const [value, setValue] = useState(message);

  const handleChange = (content, delta, source, editor) => {
    setValue(editor.getHTML());
    setContent(editor.getHTML());
  };

  useEffect(() => {
    const Clipboard = Quill.import("modules/clipboard");

    class CustomClipboard extends Clipboard {
      onPaste(e) {
        e.preventDefault();

        const clipboardData = e.clipboardData || window.clipboardData;
        const text = clipboardData.getData("text/plain");
        const html = clipboardData.getData("text/html");

        let content;
        if (html) {
          content = html;
        } else if (text) {
          // Preserve spaces and line breaks for plain text
          content = text
            .replace(/ /g, "&nbsp;") // Replace spaces with non-breaking spaces
            .replace(/\n/g, "<br>"); // Replace line breaks with <br>
        }

        // Insert processed content into Quill
        const delta = this.quill.clipboard.convert(content);
        this.quill.setContents(delta, "silent");
      }
    }

    // Register the custom clipboard module
    Quill.register("modules/clipboard", CustomClipboard, true);
  }, []);

  return (
    <div className="texteditor-container sm:h-[340px] xl:h-[515px] overflow-x-hidden">
      <ReactQuill
        value={value}
        onChange={handleChange}
        theme="snow"
        modules={modules}
        formats={formats}
        placeholder="Write something..."
      />
    </div>
  );
};

const modules = {
  toolbar: [
    ["bold", "italic", "underline"],
    ["link", "image"],
  ],
};

const formats = ["bold", "italic", "underline", "link", "image"];

export default Texteditor;
  