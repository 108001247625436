

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import profileDummyImg from "../../assets/images/jpeg/dummyimage.jpg";
import * as pdfjsLib from "pdfjs-dist";
import "pdfjs-dist/build/pdf.worker.entry";
import pdfImage from "../../assets/images/png/pdficon.png";

import defaultImg from '../../assets/images/jpeg/bg-image.jpg';

const Spotlight = ({ data, index }) => {
  const [checkIndex, setCheckIndex] = useState(true);
  const [pdf, setPdf] = useState(null);
  const [images, setImages] = useState([]);
  const [pageRendering, setPageRendering] = useState(false);
  const [fileType, setfileType] = useState("");

  function formatDate(dateString) {
    const date = new Date(dateString);

    let hours = date.getHours();
    const minutes = date.getMinutes();
    const day = date.getDate();
    const month = date.getMonth(); // 0-indexed month
    const year = date.getFullYear();

    // Format time in 12-hour format
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    const minutesFormatted = minutes < 10 ? "0" + minutes : minutes;

    const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul",
      "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const formattedDate = `${hours}:${minutesFormatted} ${ampm} | ${day} ${months[month]} ${year.toString().slice(-2)}`;

    return formattedDate;
  }

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth >= 644) {
        if (index === 3) {
          setCheckIndex(false);
        }
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [index]);

  async function loadPdf(pdfLink) {
    try {
      setPageRendering(true);
      const _PDF_DOC = await pdfjsLib.getDocument({ url: pdfLink }).promise;
      setPdf(_PDF_DOC);
      setPageRendering(false);
    } catch (error) {
      console.error("Error loading PDF: ", error.message);
    }
  }

  async function renderPage() {
    if (!pdf) return;
    setPageRendering(true);
    const imagesList = [];
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const viewport = page.getViewport({ scale: 1 });
      canvas.width = viewport.width;
      canvas.height = viewport.height;
      await page.render({ canvasContext: context, viewport }).promise;
      imagesList.push(canvas.toDataURL("image/png"));
    }
    setImages(imagesList);
    setPageRendering(false);
  }


  useEffect(() => {


    if (data?.awsUrl?.length > 0) {
      const fetchFileFromUrl = async (url) => {
        const fileName = url;
        setfileType(fileName.includes(".pdf") ? "pdf" : "");
        if (fileName.includes(".pdf")) {
          loadPdf(data?.awsUrl[0]);
        }
      };
      fetchFileFromUrl(data.awsUrl[0]);
    }
  }, [data?.awsUrl]);

  useEffect(() => {
    renderPage();
  }, [pdf]);


 
  
  const [descriptionLimit, setDescriptionLimit] = useState(200); 
  useEffect(() => {
    const updateDescriptionLimit = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth <= 768) {
        setDescriptionLimit(35);
      } else {
        setDescriptionLimit(120); 
      }
    };

    
    updateDescriptionLimit();

    
    window.addEventListener("resize", updateDescriptionLimit);

   
    return () => {
      window.removeEventListener("resize", updateDescriptionLimit);
    };
  }, []);

  function limitDescription(text, limit = 200) {
    if (!text) return "";
    return text.length > limit ? text.slice(0, limit) + "..." : text;
  }

  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = data.description;
  const plainText = tempDiv.textContent || tempDiv.innerText;


  if (checkIndex) {
    return (
      <Link
        target={data.url ? "_blank" : ""}
        to={data.user ? `/home/PostAsViewByUser/${data.id}` : `/showpdf2/${data.id}`}
        className="max-sm:rounded-[6px] rounded-[15px] border border-grey card_shadow h-full shadow-[0_8px_20px_0_#2F327D1A] flex flex-col justify-between overflow-hidden"
      >
        <div className="h-full">
          {data?.url && (
            <div className="lg:h-[300px] sm:h-[216px] h-[140px] w-full overflow-hidden relative pt-5">
              <img
                className="w-full h-full rounded-[15px] max-sm:rounded-[6px] object-contain object-top"
                src={pdfImage ? pdfImage : defaultImg}
                alt="pdfImage"
              />
            </div>
          )}
          {data.awsUrl && data.awsUrl.length > 0 && (
            <div className="lg:h-[300px] sm:h-[216px] h-[140px] w-full overflow-hidden relative">
              <img
                src={fileType === "pdf" ? images[0] || defaultImg : data.awsUrl[0] || defaultImg}
                alt="images"
                className="w-full h-full rounded-[15px] max-sm:rounded-[6px] object-cover object-top"
              />
              {images.length > 1 && (
                <span className="bg-[#ededed] p-1 px-2 md:p-2 absolute right-2 sm:right-[19px] bottom-2 sm:bottom-[17px] rounded-[40px] max-md:text-sm">
                  {images.length} Pages
                </span>
              )}
              {data?.awsUrl.length > 1 && (
                <span className="bg-[#fafafa] p-1 px-2 md:p-2 absolute right-2 sm:right-[19px] bottom-2 sm:bottom-[17px] rounded-[40px] max-md:text-sm">
                  {data?.awsUrl.length} Pages
                </span>
              )}
            </div>
          )}

          {plainText && (
            <div className="lg:pt-3 lg:pb-3 lg:px-7 sm:px-3 px-2 lg:mx-3 sm:mx-1 flex flex-col justify-between">
              <p
                className="font-normal text-sm lg:text-[15px] text-black sm:pb-3 lg:pb-6 sm:pt-4 pt-2"
                dangerouslySetInnerHTML={{ __html:limitDescription(plainText, descriptionLimit) }}
              ></p>
            </div>
          )}
        </div>
        {data.user ? (
          <div className="flex items-center pb-4 lg:px-7 sm:px-3 px-2 lg:mx-3 sm:mx-1">
            <img
              src={data?.user.profileImageUrl || profileDummyImg}
              alt="recommendations"
              className="max-sm:mr-1 mr-3 max-md:min-w-[36px] max-md:max-w-[36px] max-md:h-[36px] min-w-[48px] max-w-[48px] h-[48px] rounded-[50%]"
            />
            <div>
              <p className="font-bold text-xs sm:text-[15px] text-black">
                {data?.user?.fullName?.length > 15
                  ? data?.user?.fullName.slice(0, 13) + "..."
                  : data?.user?.fullName}
              </p>
              <p className="lg:text-base md:text-xs text-xxs text-grey text-nowrap">
                {formatDate(data.createdAt)}
              </p>
            </div>
          </div>
        ) : (
          <p className="pb-4 lg:px-7 sm:px-3 px-2 lg:mx-3 sm:mx-1 font-bold text-sm lg:text-[15px] text-black">
            {data.name.split(".")[0]}
          </p>
        )}
      </Link>
    );
  }
};

export default Spotlight;
