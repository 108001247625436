import React, { useEffect, useState } from "react";
import live_dott from "../../assets/images/svg/blue_dott7x7.svg";
import { BackArrowIcon, Notification_icon } from "../common/Icons";
import DOMPurify from "dompurify";
import bansal_sndp from "../../assets/images/jpeg/dummyimage.jpg";
import AllNotifications from "./AllNotifications"; // Import the new component
import { UseNotificationsContext } from "../ContextApis/NotificationsContext";

const Notification = ({ setSettingsPopup }) => {
  const { notification, markNotificationAsRead, markAllNotificationsAsRead } =
    UseNotificationsContext();
    const [showAll, setShowAll] = useState(false); 

  const handleNotificationClick = (id) => {
    markNotificationAsRead(id);
  };

  // const handleAllNotificationsClick = () => {
  //   markAllNotificationsAsRead();
  // };

  const handleAllNotificationsClick = () => {
  if (Array.isArray(notification) && notification.length > 0) {
    markAllNotificationsAsRead();
  } else {
    console.warn("No notifications to mark as read.");
  }
};


  function goback() {
    setSettingsPopup(false);
  }

  const timeAgo = (date) => {
    const now = new Date();
    const then = new Date(date);
    const seconds = Math.floor((now - then) / 1000);
    const interval = Math.floor(seconds / 31536000);

    if (interval > 1) {
      return `${interval} years ago`;
    }
    if (interval === 1) {
      return "1 year ago";
    }

    const months = Math.floor(seconds / 2592000);
    if (months > 1) {
      return `${months} months ago`;
    }
    if (months === 1) {
      return "1 month ago";
    }

    const days = Math.floor(seconds / 86400);
    if (days > 1) {
      return `${days} days ago`;
    }
    if (days === 1) {
      return "1 day ago";
    }

    const hours = Math.floor(seconds / 3600);
    if (hours > 1) {
      return `${hours} hours ago`;
    }
    if (hours === 1) {
      return "1 hour ago";
    }

    const minutes = Math.floor(seconds / 60);
    if (minutes > 1) {
      return `${minutes} minutes ago`;
    }
    if (minutes === 1) {
      return "1 minute ago";
    }
    return "just now";
  };
 
  
  const sortedNotifications = Array.isArray(notification)
    ? notification
        .slice()
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    : [];



  return (
    <div className="sm:max-w-[586px] sm:min-h-[500px] max-sm:h-full w-full bg-white sm:py-[40px] sm:px-[29px] sm:rounded-[40px] fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[50] flex flex-col items-start shadow-[0_8px_20px_0_rgba(0,0,0,0.2)]">
      <div className="flex items-center mb-6 mt-4 sm:hidden max-sm:ps-5">
        <span onClick={goback} className="cursor-pointer">
          <BackArrowIcon />
        </span>
        <p className="text-base font-medium ms-4"> Notifications</p>
      </div>
        <div className="flex justify-between mb-6 sm:mb-10 w-full px-5">
          <button
            onClick={() => handleAllNotificationsClick()}
            className="text-[#1a3c63] font-medium text-base sm:text-lg"
          >
            Recents
            {`(${
              Array.isArray(notification) &&
              notification.filter(
                (status) => status.notificationStatus !== "READ"
              ).length
                ? notification.filter(
                    (status) => status.notificationStatus !== "READ"
                  ).length
                : 0
            })`}
          </button>
          <button
            onClick={() => handleAllNotificationsClick()}
            className="text-[#1a3c63] font-medium text-base sm:text-lg"
          >
            Mark all as Read
          </button>
        </div>
      <div className="sm:min-h-[500px] max-sm:h-full h-[400px] w-full overflow-y-auto px-5">
        {sortedNotifications.length > 0 ? (
          sortedNotifications.map((e) => (
            <AllNotifications
              notif={e}
              handleNotificationClick={handleNotificationClick}
              timeAgo={timeAgo}
              goback={goback}
              live_dott={live_dott}
              bansal_sndp={e.profileImageUrl || bansal_sndp}
            />
          ))
          
        ) : (
          <p className="text-center text-base font-medium">
            No notifications available.
          </p>
        )}
   
      </div>
    
    </div>
  );
};

export default Notification;
