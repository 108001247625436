import React, { useEffect, useRef, useState } from "react";
import { DeleteIcon, EditIcon } from "../common/Icons";
import dropDownIcon from "../../assets/icons/dropdown-arrow.svg";

function ProfessionalDetails({ professionalData, setProfessionalData, setprofessnalError }) {
  const [newdata, setNewdata] = useState([]);
  const [errors, setErrors] = useState("");
  const [id, setId] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState({
    Industry: false,
    Company: false,
    Location: false,
    Designation: false,
  });
  const [userdata, setUserdata] = useState({
    Industry: "",
    Company: "",
    Designation: "",
    Location: "",
    Currently_working: "",
    from_first: "",
    from_end: "",
    till_first: "",
    till_end: "",
  });
  useEffect(() => {
    if (
      userdata.Industry.length !== 0 ||
      userdata.Company.length !== 0 ||
      userdata.Designation.length !== 0 ||
      userdata.Location.length !== 0
    ) {
      setprofessnalError(userdata);
    } else {
      setprofessnalError("");
    }
  }, [userdata.Industry, userdata.Company, userdata.Designation, userdata.Location]);
  const onhandelchange = (e) => {
    const { name, value } = e.target;
    setUserdata({ ...userdata, [name]: value });
    setDropdownVisible({
      Industry: name === "Industry" && value.length > 0,
      Company: name === "Company" && value.length > 0,
      Location: name === "Location" && value.length > 0,
      Designation: name === "Designation" && value.length > 0,
    });
  };
  const getColorClass = (value) => (value ? "text-black" : "text-grey");
  const validateForm = () => {
    if (!userdata.Industry) return "Industry is required";
    if (!userdata.Company) return "Company is required";
    if (!userdata.Designation) return "Designation is required";
    if (!userdata.Location) return "Location is required";
    if (!userdata.Currently_working) return "This field is required";
    if (!userdata.from_first) return "From year is required";
    if (!userdata.from_end) return "From end year is required";
    if (userdata.Currently_working !== "yes") {
      if (!userdata.till_first) return "Till year is required";
      if (!userdata.till_end) return "Till end year is required";
    }
    return "";
  };

  const industryData = ["Hindu Industry", "ABC Industry", "XYZ Industry"];
  const Company = ["AAkash Institute", "ABC Institute", "XYZ Institute"];
  const designationData = ["Maths", "Physics", "English"];
  const locationData = ["Delhi", "Hisar", "Hansi"];

  const onhandelsubmit = () => {
    const error = validateForm();
    if (error) {
      setErrors(error);
    } else {
      setNewdata([...newdata, userdata]);
      setProfessionalData([...newdata, userdata]);
      setUserdata({
        Industry: "",
        Company: "",
        Designation: "",
        Currently_working: "",
        from_first: "",
        from_end: "",
        till_first: "",
        till_end: "",
        Location: "",
      });
      setErrors("");
    }
  };
  function handleDelete(index) {
    let cloneUserData = [...newdata];
    cloneUserData.splice(index, 1);
    setNewdata(cloneUserData);
  }
  function handleEdit(index) {
    let editData = newdata.find((_, i) => i === index);
    setUserdata(editData);
  }

  const handleDropdownChange = (name, value) => {
    setUserdata({ ...userdata, [name]: value });
    setDropdownVisible({ ...dropdownVisible, [name]: false });
  };

  function updateProfessionalData() {
    let updateData = newdata.with(id, userdata);
    setNewdata(updateData);
    setProfessionalData(updateData);
    setId("");
    setUserdata({
      Industry: "",
      Company: "",
      Designation: "",
      Currently_working: "",
      from_first: "",
      from_end: "",
      till_first: "",
      till_end: "",
      Location: "",
    });
  }

  const renderDropdownOptions = (name, options) => {
    const filteredOptions = options.filter((option) =>
      option.toLowerCase().includes(userdata[name].toLowerCase())
    );

    if (filteredOptions.length === 0) {
      return <p className="text-sm py-0.5">No results found</p>;
    }

    return filteredOptions.map((option, index) => (
      <p
        key={index}
        className="text-sm py-0.5 cursor-pointer hover:bg-gray-100 rounded"
        onClick={() => handleDropdownChange(name, option)}>
        {option}
      </p>
    ));
  };

 const indRef = useRef();
 const compRef = useRef();
 const locaRef = useRef();
 const desigRef = useRef();

 useEffect(() => {
   function handleClickOutside(event) {
     if (indRef.current && !indRef.current.contains(event.target)) {
       setDropdownVisible((prev) => ({ ...prev, Industry: false }));
     }
     if (compRef.current && !compRef.current.contains(event.target)) {
       setDropdownVisible((prev) => ({ ...prev, Company: false }));
     }
     if (locaRef.current && !locaRef.current.contains(event.target)) {
       setDropdownVisible((prev) => ({ ...prev, Location: false }));
     }
     if (desigRef.current && !desigRef.current.contains(event.target)) {
       setDropdownVisible((prev) => ({ ...prev, Designation: false }));
     }
   }
   if (dropdownVisible) {
     document.addEventListener("mousedown", handleClickOutside);
   } else {
     document.removeEventListener("mousedown", handleClickOutside);
   }

   return () => {
     document.removeEventListener("mousedown", handleClickOutside);
   };
 }, [dropdownVisible]);


  return (
    <>
      <div className=" mt-6 xl:mt-8 ">
        <div className="items-center justify-between md:px-5 ">
          <form>
            <div className=" flex justify-end px-4">
              <button
                onClick={() => {
                  id === "" ? onhandelsubmit() : updateProfessionalData();
                }}
                type="button"
                className=" flex items-center justify-center gap-2 px-6 max-sm:px-4 max-sm:py-[7px] py-[10px] border border-[#BEC1C3] rounded-[100px]">
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1.99976 4.95996C1.99976 3.57925 3.11904 2.45996 4.49976 2.45996H11.4998C12.8805 2.45996 13.9998 3.57925 13.9998 4.95996V11.96C13.9998 13.3407 12.8805 14.46 11.4998 14.46H4.49976C3.11904 14.46 1.99976 13.3407 1.99976 11.96V4.95996ZM4.49976 3.45996C3.67133 3.45996 2.99976 4.13153 2.99976 4.95996V11.96C2.99976 12.7884 3.67133 13.46 4.49976 13.46H11.4998C12.3282 13.46 12.9998 12.7884 12.9998 11.96V4.95996C12.9998 4.13153 12.3282 3.45996 11.4998 3.45996H4.49976ZM7.99976 4.95996C8.2759 4.95996 8.49976 5.18382 8.49976 5.45996V7.95996H10.9998C11.2759 7.95996 11.4998 8.18382 11.4998 8.45996C11.4998 8.7361 11.2759 8.95996 10.9998 8.95996H8.49976V11.46C8.49976 11.7361 8.2759 11.96 7.99976 11.96C7.72361 11.96 7.49976 11.7361 7.49976 11.46V8.95996H4.99976C4.72361 8.95996 4.49976 8.7361 4.49976 8.45996C4.49976 8.18382 4.72361 7.95996 4.99976 7.95996H7.49976V5.45996C7.49976 5.18382 7.72361 4.95996 7.99976 4.95996Z"
                    fill="#303135"
                  />
                </svg>
                <span className=" text-black text-base font-normal leading-6">
                  {id === "" ? "Add" : "Update"}
                </span>
              </button>
            </div>
            <div className="h_professional overflow-y-auto px-4 sm:px-6 max-md:mt-4 mt-6">
              {errors && <p className="text-red-500">{errors}</p>}
              {newdata.length !== 0 ? (
                <div className="flex flex-col gap-3 max-md:mt-4 mb-4">
                  {newdata.map((value, index) => {
                    return (
                      <div
                        key={index}
                        className="w-full max-md:rounded-[100px] bg-[#F7F7F7] py-3 px-9 rounded-xl mx-auto  overflow-auto max-sm:text-xs ">
                        <div className="flex items-center">
                          <div key={index} className="w-full">
                            <p>{value.Industry}</p>
                            <p>{value.Company}</p>
                            <p>{value.Designation}</p>
                            <p>{value.Location}</p>
                            <p>
                              {`01-${value.from_first <= 9 ? "0" : ""}${value.from_first}-${
                                value.from_end
                              }`}{" "}
                              {value.Currently_working !== "yes" ? "/" : ""}{" "}
                              {value.Currently_working !== "yes"
                                ? `30-${value.till_first <= 9 ? "0" : ""}${value.till_first}-${
                                    value.till_end
                                  }`
                                : ""}
                            </p>
                          </div>
                          <div className="flex gap-6 items-center">
                            <button
                              type="button"
                              className="w-4 h-4"
                              onClick={() => {
                                handleEdit(index);
                                setId(index);
                              }}>
                              <EditIcon />
                            </button>
                            <button
                              type="button"
                              className="w-4 h-4"
                              onClick={() => handleDelete(index)}>
                              <DeleteIcon />
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : null}
              <div className=" flex flex-col gap-6 ">
                <div ref={indRef}>
                  <label
                    className=" text-base max-sm:text-sm font-medium text-black leading-5"
                    htmlFor="Industry">
                    Industry*
                  </label>
                  <div className="border border-[#BEC1C3] mt-[6px] rounded-[100px] relative flex items-center ps-7 pe-4 cursor-pointer">
                    <input
                      type="text"
                      onChange={onhandelchange}
                      className="w-full outline-none h-[40px] max-sm:text-sm bg-transparent"
                      name="Industry"
                      value={userdata.Industry}
                      placeholder="Industry"
                      id="Industry"
                    />
                    <img src={dropDownIcon} alt="dropDownIcon" />
                    {dropdownVisible.Industry && (
                      <div className="absolute top-[45px] left-0 bg-white border border-gray shadow w-full px-7 py-2 z-[1] rounded-xl dropdown">
                        {renderDropdownOptions("Industry", industryData)}
                      </div>
                    )}
                  </div>
                </div>
                <div ref={compRef}>
                  <label
                    className=" text-base font-medium text-black leading-5 max-sm:text-sm"
                    htmlFor="Company">
                    Company name*
                  </label>
                  <div className="  border border-[#BEC1C3]  mt-[6px] rounded-[100px] flex items-center ps-7 pe-4 cursor-pointer relative">
                    <input
                      type="text"
                      onChange={onhandelchange}
                      className="w-full outline-none max-sm:text-sm h-[40px]"
                      name="Company"
                      id="Company"
                      placeholder="Company name"
                      value={userdata.Company}
                    />
                    <img src={dropDownIcon} alt="dropDownIcon" />
                    {dropdownVisible.Company && (
                      <div className="absolute top-[45px] left-0 bg-white border border-gray shadow w-full px-7 py-2 z-[1] rounded-xl dropdown">
                        {renderDropdownOptions("Company", Company)}
                      </div>
                    )}
                  </div>
                </div>

                <div>
                  <label
                    className=" text-base font-medium text-black leading-5 max-sm:text-sm"
                    htmlFor="Currently_working">
                    Currently working here*
                  </label>
                  <div className="  border border-[#BEC1C3]  mt-[6px] rounded-[100px]">
                    <select
                      onChange={onhandelchange}
                      className={`w-full outline-none max-sm:text-sm h-[40px] px-7 ${getColorClass(
                        userdata.Currently_working
                      )}`}
                      name="Currently_working"
                      id="Currently_working"
                      value={userdata.Currently_working}>
                      <option disabled value="">
                        Select an option
                      </option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>
                </div>
                <div className="w-full flex gap-4">
                  <div className="w-1/2">
                    <label
                      className=" text-base font-medium text-black leading-5 max-sm:text-sm"
                      htmlFor="From_first">
                      From*
                    </label>
                    <div className="flex gap-1">
                      <div className=" border border-[#BEC1C3]  mt-[6px] h-[40px] rounded-[100px] flex items-center   w-full max-sm:text-sm">
                        <select
                          id="month"
                          className={`font-normal text-[14px] max-sm:bg-[length:16px_16px] ps-2 md:px-3 sm:text-base w-full outline-none ${getColorClass(
                            userdata.from_first
                          )}`}
                          value={userdata.from_first}
                          name="from_first"
                          onChange={onhandelchange}>
                          <option disabled value="">
                            MM
                          </option>
                          {[...Array(12)].map((_, i) => (
                            <option key={i + 1} value={i + 1}>
                              {i + 1}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className=" border border-[#BEC1C3]  mt-[6px] h-[40px] rounded-[100px] flex items-center w-full max-sm:text-sm">
                        <select
                          id="year"
                          className={`font-normal text-[14px] max-sm:bg-[length:16px_16px] ps-2 md:px-3 sm:text-base w-full outline-none ${getColorClass(
                            userdata.from_end
                          )}`}
                          value={userdata.from_end}
                          name="from_end"
                          onChange={onhandelchange}>
                          <option disabled value="">
                            YYYY
                          </option>
                          {[...Array(100)].map((_, i) => {
                            const year = new Date().getFullYear() - i;
                            return (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  {userdata.Currently_working !== "yes" && (
                    <div className="w-1/2 ">
                      <label
                        className=" text-base font-medium text-black leading-5 max-sm:text-sm"
                        htmlFor="till_first">
                        Till*
                      </label>
                      <div className=" flex gap-1">
                        <div className=" border border-[#BEC1C3]  mt-[6px] h-[40px] rounded-[100px] flex items-center w-full max-sm:text-sm">
                          <select
                            id="month"
                            className={`font-normal text-[14px] max-sm:bg-[length:16px_16px] ps-2 md:px-3 sm:text-base w-full outline-none ${getColorClass(
                              userdata.till_first
                            )}`}
                            name="till_first"
                            value={userdata.till_first}
                            onChange={onhandelchange}>
                            <option disabled value="">
                              MM
                            </option>
                            {[...Array(12)].map((_, i) => (
                              <option key={i + 1} value={i + 1}>
                                {i + 1}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className=" border border-[#BEC1C3]  mt-[6px] h-[40px] rounded-[100px] flex items-center w-full max-sm:text-sm">
                          <select
                            id="year"
                            className={`font-normal text-[14px] max-sm:bg-[length:16px_16px] px-2 md:px-3 sm:text-base w-full outline-none ${getColorClass(
                              userdata.till_end
                            )}`}
                            name="till_end"
                            value={userdata.till_end}
                            onChange={onhandelchange}>
                            <option disabled value="">
                              YYYY
                            </option>
                            {[...Array(100)].map((_, i) => {
                              const year = new Date().getFullYear() - i;
                              return (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div ref={desigRef}>
                  <label
                    className=" text-base font-medium text-black leading-5 max-sm:text-sm"
                    htmlFor="Designation">
                    Designation*
                  </label>
                  <div className="  border border-[#BEC1C3]  mt-[6px] rounded-[100px] flex items-center ps-7 pe-4 cursor-pointer relative">
                    <input
                      type="text"
                      onChange={onhandelchange}
                      className="w-full outline-none max-sm:text-sm h-[40px]"
                      name="Designation"
                      id="Designation"
                      placeholder="Select Designation"
                      value={userdata.Designation}
                    />
                    <img src={dropDownIcon} alt="dropDownIcon" />
                    {dropdownVisible.Designation && (
                      <div className="absolute top-[45px] left-0 bg-white border border-gray shadow w-full px-7 py-2 z-[1] rounded-xl dropdown">
                        {renderDropdownOptions("Designation", designationData)}
                      </div>
                    )}
                  </div>
                </div>
                <div ref={locaRef}>
                  <label
                    className=" text-base font-medium text-black leading-5 max-sm:text-sm"
                    htmlFor="Location">
                    Location*
                  </label>
                  <div className="  border border-[#BEC1C3]  mt-[6px] rounded-[100px] flex items-center ps-7 pe-4 cursor-pointer relative">
                    <input
                      onChange={onhandelchange}
                      className="w-full outline-none max-sm:text-sm h-[40px]"
                      name="Location"
                      id="Location"
                      placeholder="Enter Location"
                      value={userdata.Location}
                    />
                    <img src={dropDownIcon} alt="dropDownIcon" />
                    {dropdownVisible.Location && (
                      <div className="absolute top-[45px] left-0 bg-white border border-gray shadow w-full px-7 py-2 z-[1] rounded-xl dropdown">
                        {renderDropdownOptions("Location", locationData)}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default ProfessionalDetails;
