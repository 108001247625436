


import React, { useState, useEffect } from "react";
import Book_icon from "../../assets/images/png/Book_icon.png";

const Genres = ({ data, index }) => {
  const [genresData, setGenresData] = useState(true);
  const [img, setImg] = useState("");

  const fileId = data.id || data;
  // console.log(fileId,"idid");
  useEffect(() => {
    const fetchImage = async () => {
      try {

        // Image fetch karne ki API call
        const response = await fetch(
          `http://api.wised.in/api/v1/auth/downloadImage?fileId=${fileId}`,
          
        );
        

        if (!response.ok) {
          throw new Error("Image fetch failed!");
        }

        // Image ke liye blob URL create karna
        const blob = await response.blob();
       const imageUrl = URL.createObjectURL(blob);
        console.log(imageUrl,"././././/./")
        setImg(imageUrl);
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    fetchImage();

    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth >= 644) {
        if (index >= 3) {
          setGenresData(false);
        }
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [data, index]);

  console.log(data,'imagexcv')

  return (
    <>
      <div className="relative rounded-xl overflow-hidden">
        {/* Dynamically fetched image */}
        {img ? (
          <img src={data.id} alt="Genre" className="w-full" />
        ) : (
          <div className="text-white">Loading image...</div>
        )}
        <div className="lg:py-5 sm:py-2 py-1 absolute bg-[rgba(255, 255, 255, 0.4)] w-full bottom-0 backdrop-blur-[5.63793px] text-center bg-[#c8c6c666]">
          <p className="text-white font-semibold lg:text-xl px-3 sm:text-base text-xxs pb-3">
            {data.name}
          </p>
          <div>
            <img
              src={Book_icon}
              alt="Book Icon"
              className="pb-2 mx-auto lg:w-[36px] sm:w-[21px] w-[10px]"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Genres;
