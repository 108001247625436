import { createContext, useContext, useEffect, useState } from "react";
import Loader from "../Loader";
import { path } from "../common/Api";
import { getToken } from "./TokenStore";
const NavDataContext = createContext();

export const NavDataProvider = ({ children }) => {
  const [global, setGlobal] = useState([]);

  // Global
  async function getGlobal(query) {
    setGlobal([])
    let token = getToken();
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
      };
      // Make the API call
      const response = await fetch(`${path}search/?query=${query}`, requestOptions);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch Recent data");
      }
      const data = await response.json();
      setGlobal(data);
    } catch (error) {
      console.error("Error:", error);
    }
  }


  return (
    <NavDataContext.Provider value={{ global, getGlobal }}>
      {children}
    </NavDataContext.Provider>
  );
};

export const UseNavDataContext = () => {
  return useContext(NavDataContext);
};
