import React, { useEffect, useState, useRef } from "react";
import { Worker, Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { UsePDFContext } from "../ContextApis/PDF";
import { useParams } from "react-router-dom";
import PDFdemmy from "../../assets/images/png/pdficon.png";
import { Helmet } from "react-helmet-async";

const PdfViewer = () => {
  const { pdf, fetchPdf } = UsePDFContext();
  const pathUrl = useParams();
  let id = pathUrl.id.split(".pdf=")[1];
  let pdfName = pathUrl.id.split(".pdf=")[0].split("$$")[0];
  const [pdfUrl, setPdfUrl] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(1.3); // Default to 100%
  const [totalPages, setTotalPages] = useState(0);
  const [isMouseMoving, setIsMouseMoving] = useState(false);
  const [opacity, setOpacity] = useState(1);
  const toolRef = useRef(null);
  const viewerRef = useRef(null);

  let side = document.querySelector(".rpv-core__minimal-button--selected");
  let bottom = document.querySelector(".rpv-toolbar");
  let themebtn = document.querySelector(
    'div[aria-describedby="rpv-core__tooltip-body-theme-switch"]'
  );
  let upload = document.querySelector(
    'div[aria-describedby="rpv-core__tooltip-body-open"]'
  );
  let download = document.querySelector(
    'div[aria-describedby="rpv-core__tooltip-body-get-file"]'
  );
  let print = document.querySelector(
    'div[aria-describedby="rpv-core__tooltip-body-print"]'
  );
  document.addEventListener("keydown", (event) => {
    // Check if the down or up arrow key is pressed
    if (event.key === "ArrowDown") {
      let downBtn = document.querySelector('button[aria-label="Next page"]');
      if (downBtn) {
        downBtn.click();
      }
    } else if (event.key === "ArrowUp") {
      let upBtn = document.querySelector('button[aria-label="Previous page"]');
      if (upBtn) {
        upBtn.click();
      }
    }
  });
  document.addEventListener("keydown", (event) => {
    // Check if the down arrow key is pressed
    if (event.key === "ArrowDown") {
      // Select the button with aria-label "Next page"
      let downBtn = document.querySelector('button[aria-label="Next page"]');
      if (downBtn) {
        // Click the button
        downBtn.click();
      }
    }
  });

  useEffect(() => {
    if (side && bottom) {
      const existingSvg = side.querySelector("svg");
      if (existingSvg) {
        existingSvg.remove();
      }
      const svgElement = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "svg"
      );
      svgElement.setAttribute("height", "19");
      svgElement.setAttribute("width", "19");
      svgElement.setAttribute("viewBox", "0 0 24 24");
      const pathElement = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "path"
      );
      pathElement.setAttribute(
        "d",
        "M20.47 22c1.141 0 2.015-.314 2.62-.942.607-.628.91-1.533.91-2.715V5.657c0-1.182-.303-2.087-.91-2.715C22.486 2.314 21.612 2 20.47 2H3.53c-1.141 0-2.015.312-2.62.936C.302 3.56 0 4.467 0 5.656v12.687c0 1.182.303 2.087.91 2.715.605.628 1.479.942 2.62.942h16.94zM8.538 19.352H3.796c-.411 0-.723-.107-.936-.322-.212-.214-.319-.55-.319-1.008V5.978c0-.458.107-.794.32-1.008.212-.215.524-.322.935-.322h4.742v14.704zm11.655 0h-9.315V4.648h9.315c.411 0 .725.107.941.322.216.214.325.55.325 1.008v12.044c0 .458-.109.794-.325 1.008-.216.215-.53.322-.94.322zM6.37 9.335a.566.566 0 00.42-.155c.102-.103.154-.247.154-.432V7.075c0-.406-.192-.61-.574-.61H4.72c-.39 0-.585.204-.585.61v1.673c0 .391.195.587.585.587H6.37zm0 4.089a.566.566 0 00.42-.155c.102-.104.154-.248.154-.432v-1.685c0-.192-.052-.34-.154-.443a.566.566 0 00-.42-.155H4.72c-.191 0-.336.052-.436.155-.099.104-.149.251-.149.443v1.685c0 .184.052.328.155.432.102.103.246.155.43.155H6.37zm0 4.088a.566.566 0 00.42-.155c.102-.103.154-.247.154-.432v-1.684c0-.392-.192-.587-.574-.587H4.72c-.39 0-.585.195-.585.587v1.684c0 .392.195.587.585.587H6.37z"
      );
      pathElement.setAttribute("fill", "#fff");
      svgElement.appendChild(pathElement);
      side.appendChild(svgElement);
      bottom.appendChild(side);
      if (window.innerWidth >= 640) {
        side.click();
      }
    }
    if (themebtn) {
      themebtn.style.display = "none";
    }
    if (upload) {
      upload.style.display = "none";
    }
    if (download) {
      download.style.display = "none";
    }
    if (print) {
      print.style.display = "none";
    }
  }, [side, bottom, print, upload, download]);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  useEffect(() => {
    const loadPdf = async () => {
      await fetchPdf(id);
    };

    loadPdf();

    if (id) {
      document.body.classList.add("hide_scroll");
    }

    return () => {
      document.body.classList.remove("hide_scroll");
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
      }
    };
  }, [id]);

  useEffect(() => {
    if (pdf) {
      const url = URL.createObjectURL(pdf);
      setPdfUrl(url);

      return () => URL.revokeObjectURL(url);
    }
  }, [pdf]);

  const handleZoomChange = (props) => {
    const scale = props.scale || 1;
    setZoomLevel(Math.round(scale * 200));
  };

  const handleDocumentLoad = (props) => {
    setTotalPages(props.doc.numPages);
    setZoomLevel(1);
  };

  const calculateDefaultScale = () => {
    // Adjust based on screen width
    return window.innerWidth < 640 ? 0.5 : 1.3;
  };

  return (
    <>
      <Helmet>
        <title>Question Papers for All Subjects and Exams | Wised.in</title>
        <meta
          name="description"
          content="Government Private Engineering Universities, State & Central Exams, Other exams"
        />

        <meta property="og:url" content="https://Wised.in/question-papers" />
      </Helmet>
      <div
        style={{ height: "100vh", width: "100%" }}
        className="flex flex-col min-h-screen pdf_div"
      >
        {pdfUrl ? (
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
            <div className="w-full h-[100vh]">
              <Viewer
                ref={viewerRef}
                fileUrl={pdfUrl}
                plugins={[defaultLayoutPluginInstance]}
                defaultScale={calculateDefaultScale()} // Sets the default zoom level to 100%
                onZoom={handleZoomChange}
                onDocumentLoad={handleDocumentLoad}
                onError={(error) => console.error("PDF Viewer Error:", error)}
              />
            </div>
          </Worker>
        ) : (
          <div className="h-screen flex items-center justify-center bg-[#e0dfdf35]">
            <svg
              width="70"
              height="70"
              viewBox="0 0 32 32"
              focusable="false"
              aria-hidden="true"
              role="presentation"
              className="absolute z-10 pdf_svg"
            >
              <g fill="none">
                <path
                  fill="#D0021B"
                  d="M9 3h9.586a1 1 0 01.707.293l6.415 6.414a1 1 0 01.293.707V26A3 3 0 0123 29H9a3 3 0 01-3-3V6a3 3 0 013-3z"
                ></path>
                <path
                  fill="#fff"
                  fill-opacity="0.5"
                  d="M19.286 3.286l5.01 5.009 1.412 1.412a1 1 0 01.203.293H21a2 2 0 01-2-2V3.09a1 1 0 01.286.196z"
                ></path>
                <path
                  fill="#fff"
                  d="M13.133 16.998c0 .354-.054.66-.162.918a1.745 1.745 0 01-1.044 1.012 2.057 2.057 0 01-.693.122h-.621v2.322H9.2V15h1.98c.228 0 .457.033.688.099a1.655 1.655 0 011.089.945c.118.258.176.576.176.954zm-1.35.027c0-.288-.069-.495-.207-.621a.72.72 0 00-.504-.189h-.459v1.665h.459a.665.665 0 00.504-.22c.138-.148.207-.359.207-.635zm6.854 1.179c0 .48-.052.915-.157 1.305-.106.39-.266.723-.482.999a2.14 2.14 0 01-.824.639c-.333.15-.727.225-1.183.225H14.2V15h1.791c.456 0 .85.075 1.183.225.334.15.608.364.824.643.216.28.376.615.482 1.008.105.394.157.836.157 1.328zm-1.449 0c0-.642-.11-1.126-.328-1.454-.22-.327-.503-.49-.851-.49h-.351v3.852h.351c.348 0 .631-.163.85-.49.22-.328.329-.8.329-1.418zm3.961-1.899v1.296h1.521v1.233h-1.512v2.538H19.7V15h3.105v1.305h-1.656z"
                ></path>
              </g>
            </svg>
            <span className="w-[154px] h-[154px] rounded-full custom_spiner"></span>
          </div>
        )}
      </div>
    </>
  );
};

export default PdfViewer;
