import React, { useEffect, useState } from "react";
import Spotlight from "../../components/Home/Spotlight";
import { LeftIcon } from "../../components/common/Icons";
import InfoIcon from "../../assets/images/png/InfoIcon.png";
import InfoPopup from "../../components/popup/InfoPopup";
import { Link, useParams } from "react-router-dom";
import { UseHomeContext } from "../ContextApis/HomeContext";
import { UseInterestContext } from "../ContextApis/SettingsContext";
import { UseProfileContext } from "../ContextApis/ProfileContext";

import bansal_sndp from "../../assets/images/jpeg/dummyimage.jpg";
const AllUpdatedContent = () => {
  const [showsetInfoPopup, setCategoryShowPopUp] = useState(false);
  const { updatedcontents, GetUpdatedcontents } = UseHomeContext();
  const { getInterestData } = UseInterestContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [userData, setUserData] = useState({});

  const { profileData, getOtherUserProfileData } = UseProfileContext();

  const { username } = useParams();
  const itemsPerPage = 9; // Display 9 items per page
  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  // Calculate the total number of pages
  const totalPages = Math.ceil(updatedcontents.length / itemsPerPage);

  // Get the data for the current page
  const currentItems = updatedcontents.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  useEffect(() => {
    if (username !== undefined) {
      getOtherUserProfileData();
    }
    if (username === undefined) {
      setUserData(profileData);
    }
  }, [username || profileData]);

  // Handle previous page
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
      scrollToTop();
    }
  };

  // Handle next page
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
      scrollToTop();
    }
  };

  async function callApis() {
    let storeTopic = sessionStorage.getItem("topic");
    let insData = await getInterestData();
    GetUpdatedcontents(
      storeTopic !== null
        ? storeTopic
        : typeof insData === "object" && Object.values(insData).length !== 0
        ? ""
        : ""
    );
  }
  useEffect(() => {
    callApis();
  }, []);
  return (
    <>
      {showsetInfoPopup && (
        <div className="backdrop-blur-[10px] z-50 fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] h-[100vh] w-full"></div>
      )}
      {showsetInfoPopup && (
        <InfoPopup setshowsetInfoPopup={setCategoryShowPopUp} />
      )}
      <section className="px-6 max-sm:px-4">
        <div className="lg:pt-[30px] sm:pt-[50px] pt-4 flex items-center sm:justify-center justify-start lg:pb-[30px] sm:pb-[50px] pb-4">
          <Link to="/home">
            <LeftIcon />
          </Link>
          <div className="flex items-center justify-center">
            <p className=" text-black font-semibold sm:text-lg text-base text-center pe-2">
              {" "}
              Updated contents{" "}
            </p>
            <a href="">
              <img
                src={InfoIcon}
                alt=""
                onClick={(e) => {
                  e.preventDefault();
                  return setCategoryShowPopUp(true);
                }}
              />
            </a>
          </div>
        </div>
        <div className="container mx-auto grid grid-rows-1 sm:grid-cols-3 grid-cols-2 xl:gap-x-[77px] sm:gap-x-[42px] gap-x-4 lg:mb-10 sm:mb-12 mb-4 gap-y-7 max-sm:gap-y-4">
         
              {currentItems.map((item, index) => {
                return (
                  <div key={index} className={`mt-5`}>
                    <Spotlight data={item} />
                  </div>
                );
              })}
       
          
        </div>
        <div className="flex justify-center items-center gap-4 mt-6 md:mb-4">
          <button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            className="px-4 py-1 md:py-2 bg-[#4d8aff] text-white rounded disabled:opacity-50 max-md:text-sm"
          >
            Prev
          </button>
          <p className="max-md:text-sm">
            Page {currentPage} of {totalPages}
          </p>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className="px-4 py-1 md:py-2 bg-[#4d8aff] text-white rounded  disabled:opacity-50 max-md:text-sm"
          >
            Next
          </button>
        </div>
      </section>
    </>
  );
};
export default AllUpdatedContent;
