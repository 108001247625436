import React, { useCallback, useEffect, useState } from "react";
import { UseProfileContext } from "../ContextApis/ProfileContext";
import _ from "lodash";

const UserPersonalDetails = ({ setFormData, formData }) => {
  const [user, setUser] = useState(formData?.username || "");
  const [usernameAvailability, setUsernameAvailability] = useState(null);
  const [isUsernameValid, setIsUsernameValid] = useState("");
  const { checkUsernameAvailability } = UseProfileContext();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "username") {
      setUser(value);
    }
  };

  const handleDayChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      day: value,
    }));
  };

  const handleMonthChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      month: value,
    }));
  };

  const handleYearChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      year: value,
    }));
  };

  const onsubmit = (e) => {
    e.preventDefault();
    setFormData({
      username: "",
      day: "",
      month: "",
      year: "",
    });
  };

  const debouncedCheckUsernameAvailability = useCallback(
    _.debounce(async (username) => {
      try {
        const response = await checkUsernameAvailability(username);
        setUsernameAvailability(response);
        setIsUsernameValid(response?.available ?? false);
      } catch (error) {
        console.error("Error checking username availability:", error);
      }
    }, 2000),
    [checkUsernameAvailability]
  );

  useEffect(() => {
    if (user) {
      debouncedCheckUsernameAvailability(user);
    } else {
      setIsUsernameValid(true);
    }
  }, [user, debouncedCheckUsernameAvailability]);

  const getColorClass = (value) => (value ? "text-black " : "text-grey");

  return (
    <div>
      <form
        onSubmit={onsubmit}
        action=""
        className="h-full flex flex-col justify-between sm:px-5 md:px-10">
        <div className="px-4 sm:px-6">
          <div className="flex flex-col gap-1 mt-[41px] md:mt-6">
            <label htmlFor="name" className="font-medium max-sm:text-sm">
              Name*
            </label>
            <input
              className="outline-none border border-gray h-[40px] px-4 sm:px-6 rounded-[100px] text-black font-normal max-sm:text-sm"
              type="text"
              id="name"
              name="name"
              required
              onChange={handleInputChange}
              value={formData.name}
              placeholder="Your name"
            />
          </div>
          <div className="flex flex-col gap-1 mt-[41px] md:mt-6">
            <label htmlFor="username" className="font-medium max-sm:text-sm">
              Username*
            </label>
            <input
              className="outline-none border border-gray h-[40px] px-4 sm:px-6 rounded-[100px] text-black font-normal max-sm:text-sm"
              type="text"
              id="username"
              name="username"
              required
              onChange={handleInputChange}
              value={formData.username}
              placeholder="Choose a username"
            />
          </div>
          {user &&
            (isUsernameValid ? (
              <p className="text-green-500 text-sm mt-2">Username is available.</p>
            ) : (
              <p className="text-red-500 text-sm mt-2">Username is not available.</p>
            ))}
          <h2 className="font-medium text-[14px] text-black mt-[30px] md:mt-6">Enter DOB*</h2>
          <div className="flex gap-2">
            <div className="flex flex-col gap-1 w-1/3">
              <label
                htmlFor="date"
                className="font-medium text-black text-xs md:text-base capitalize">
                Date
              </label>
              <select
                id="day"
                className={`font-normal border border-gray h-[40px] px-4 rounded-3xl text-[14px] sm:text-base w-full outline-none bg-transparent ${getColorClass(
                  formData.day
                )}`}
                value={formData.day}
                onChange={handleDayChange}>
                <option disabled value="">
                  DD
                </option>
                {[...Array(31)].map((_, i) => (
                  <option key={i + 1} value={i + 1}>
                    {i < 9 && 0}
                    {i + 1}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col gap-1 w-1/3">
              <label
                htmlFor="month"
                className="font-medium text-black text-xs md:text-base capitalize">
                Month
              </label>
              <select
                id="month"
                className={`font-normal border border-gray h-[40px] px-4 rounded-3xl text-[14px] sm:text-base w-full outline-none ${getColorClass(
                  formData.month
                )}`}
                value={formData.month}
                onChange={handleMonthChange}>
                <option disabled value="">
                  MM
                </option>
                {[...Array(12)].map((_, i) => (
                  <option key={i + 1} value={i + 1}>
                    {i < 9 && 0}
                    {i + 1}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col gap-1 w-1/3">
              <label
                className="font-medium text-black text-xs md:text-base capitalize"
                htmlFor="year">
                Year
              </label>
              <select
                id="year"
                className={`border border-gray h-[40px] px-4 rounded-3xl font-normal text-[14px] sm:text-base w-full outline-none ${getColorClass(
                  formData.year
                )}`}
                value={formData.year}
                onChange={handleYearChange}>
                <option disabled value="">
                  YYYY
                </option>
                {[...Array(100)].map((_, i) => {
                  const year = new Date().getFullYear() - i;
                  return (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UserPersonalDetails;
