import React, { useState } from "react";
import { BackArrowIcon } from "../common/Icons";
import Texteditor from "./Texteditor";
import { UseCreatePostData } from "../ContextApis/CreatePostContext";
import { toast } from "react-toastify";

function Writeup({ value, setMainPopup }) {
  const [writeupcreate, setWriteupcreate] = useState(false);
  const [editorContent, setEditorContent] = useState("");
  const writsUpsData = {
    type: "WRITE_UPS",
    description: editorContent,
  };
  const { addPost } = UseCreatePostData();
  const onsubmit = (e) => {
    e.preventDefault();
    if (editorContent.length > 0) {
      addPost(writsUpsData);
      setWriteupcreate(true);
      value(false);
      setMainPopup(false);
    } else {
      toast.info("Please write something...")
    }
  };



  return (
    <form
      onSubmit={onsubmit}
      className="bg-white fixed top-0 left-0 sm:top-1/2 sm:left-1/2 sm:-translate-x-1/2 sm:-translate-y-1/2 z-[500] px-4 lg:px-[38px] md:shadow md:rounded-[40px] h-screen sm:h-[500px] 2xl:h-[676px] sm:w-[586px] w-full py-4  sm:py-[26px] md:py-[31px] sm:rounded-[40px] mx-auto">
      <p className="font-normal text-sm md:text-base">Write-up</p>
      <div className="flex items-center justify-between">
        <span className="cursor-pointer" onClick={() => value(false)}>
          <BackArrowIcon />
        </span>
        <button className="cursor-pointer" type="submit">
          <button
            className={`font-normal text-sm md:text-base  h-[40px] w-[78px] sm:h-[60px] duration-500
               rounded-[100px] text-nowrap capitalize flex bg-primary text-white items-center justify-center sm:w-[114px] border border-grey`}>
            Post
          </button>
        </button>
      </div>
      <div className="mt-4 sm:mt-8 overflow-auto  ">
        <Texteditor message={editorContent} setContent={setEditorContent} />
      
      </div>
    </form>
  );
}

export default Writeup;
