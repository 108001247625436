import React, { createContext, useContext, useEffect, useState } from "react";
import Loader from "../Loader";
import { path } from "../common/Api";
import { getEducation, getToken } from "./TokenStore";
const QuestionPaperContext = createContext();

export function QuestionPaperContextProvider({ children }) {
  const [questionPapers, setQuestionPapers] = useState([]);
  const [allQuestionPapers, setAllQuestionPapers] = useState([]);
  const [pdfFileName, setPdfFileName] = useState([]);
  const [examName, setExamName] = useState(null);
  const [AllExam, setAllExam] = useState([]);
  const [loading, setLoading] = useState(false);

  async function getQuestionPaper(universityParem) {
    setLoading(true);
    setQuestionPapers([]);
    let universityData = {};
    if (universityParem !== undefined) {
      universityData = universityParem;
    } else if (localStorage.getItem("universityData") !== null) {
      universityData = JSON.parse(localStorage.getItem("universityData"));
    } else {
      universityData = {
        university: "Babasaheb Ambedkar Marathwada University Aurangabad (BAMU)",
        Specialisation: "Chemical Engineering",
        Semester: "3",
      };
    }

    try {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };
      // Make the API call
      const url = `${path}auth/folder_contents?folderPath=Wised Content QP/${universityData.university}/${universityData.Specialisation}/${universityData.Semester}`;

      const response = await fetch(url, requestOptions);

      // Check if the response is not okay
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed To Get Education Details");
      } else {
        const data = await response.json();
        setQuestionPapers(data);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      setLoading(false);
    }
  }

  async function getAllQuestionPaper(universityParem) {
    let educaDtls = getEducation();
    setLoading(true);
    let universityData = {};
    if (universityParem !== undefined) {
      universityData = universityParem;
    } else if (localStorage.getItem("universityData") !== null) {
      universityData = JSON.parse(localStorage.getItem("universityData"));
    } else if (educaDtls) {
      universityData = {
        universityName: educaDtls?.universityName
          ? educaDtls.universityName
          : "Babasaheb Ambedkar Marathwada University Aurangabad (BAMU)",
        specializationStream: educaDtls?.specializationStream
          ? educaDtls.specializationStream
          : "Chemical Engineering",
        currentSemester: educaDtls?.currentSemester ? educaDtls.currentSemester : "3",
      };
    } else {
      universityData = {
        university: "Babasaheb Ambedkar Marathwada University Aurangabad (BAMU)",
        Specialisation: "Chemical Engineering",
        Semester: "3",
      };
    }

    try {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };
      // Make the API call
      const url = `${path}auth/all_contents?folderPath=Wised Content QP/${universityData.universityName}/${universityData.specializationStream}/${universityData.currentSemester}`;

      const response = await fetch(url, requestOptions);

      // Check if the response is not okay
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed To Get Education Details");
      } else {
        const data = await response.json();
        setAllQuestionPapers(data.items);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      setLoading(false);
    }
  }

  async function getQuestionPaperPDF(paperName) {
    setPdfFileName([]);
    let universityData = {};
    if (localStorage.getItem("universityData") !== null) {
      universityData = JSON.parse(localStorage.getItem("universityData"));
    } else {
      universityData = {
        university: "Babasaheb Ambedkar Marathwada University Aurangabad (BAMU)",
        Specialisation: "Chemical Engineering",
        Semester: "3",
      };
    }
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };
      // Make the API call
      const url = `${path}auth/folder_contents?folderPath=Wised Content QP/${universityData.university}/${universityData.Specialisation}/${universityData.Semester}/${paperName}`;

      const response = await fetch(url, requestOptions);
      // Check if the response is not okay
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed To Get Education Details");
      } else {
        const data = await response.json();
        setPdfFileName(data);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  }
  async function getExamName(query) {
    let token = getToken();
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.accessToken}`,
        },
      };
      // Make the API call
      const url = `${path}user/profile/interests/Exam/${query}`;

      const response = await fetch(url, requestOptions);
      // Check if the response is not okay
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed To Get Education Details");
      } else {
        const data = await response.json();
        setExamName(data.mapData);
        return data.mapData;
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  }
  async function getAllExam(ExamParams) {
    setLoading(true);
    let examData = {};
    if (ExamParams !== undefined) {
      examData = ExamParams;
    } else {
      examData = localStorage.getItem("exam");
    }
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };
      // Make the API call
      const url = `${path}auth/mha_pdf_contents`;

      const response = await fetch(url, requestOptions);

      // Check if the response is not okay
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed To Get Education Details");
      } else {
        const data = await response.json();
        setAllExam(data.folder_structure.items);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      setLoading(false);
    }
  }
  const [username, setUsername] = useState(
    localStorage.getItem("userName") || sessionStorage.getItem("userName")
  );
  useEffect(() => {
    if (username) {
      getAllExam();
    }
  }, [username]);

  return (
    <QuestionPaperContext.Provider
      value={{
        questionPapers,
        pdfFileName,
        getQuestionPaper,
        getQuestionPaperPDF,
        getAllQuestionPaper,
        allQuestionPapers,
        getExamName,
        examName,
        getAllExam,
        AllExam,
        loading,
      }}>
      {loading && <Loader />}
      {children}
    </QuestionPaperContext.Provider>
  );
}

export function UseQuestionPaperContext() {
  return useContext(QuestionPaperContext);
}
