import React, { useState } from "react";
import helpGif from "../../assets/gif/help-gif.gif";
import { BackArrowIcon, BottomArrow, DropDownArrowIcon, DropDownIcon } from "../common/Icons";
import { Link } from "react-router-dom";

const Helps = () => {
  const [openTab, setOpenTab] = useState(1);
  const toggleTab = (tabIndex) => {
    setOpenTab(openTab === tabIndex ? null : tabIndex);
  };

  return (
    <div className="lg:px-16 xl:px-[95px] px-5 mt-4 sm:mt-12 lg:mt-[54px]">
      <div className="flex items-center gap-1.5 sm:hidden mb-4">
        <Link to="/settings">
          <BackArrowIcon />
        </Link>
        <h2 className="font-medium text-base text-black capitalize">Help</h2>
      </div>
      <div className="flex flex-col items-center justify-center px-3">
        <h2 className="font-normal md:font-light text-lg sm:text-xl lg:text-6xl xl:text-2xl text-black text-center mb-[18px] md:mt-[30px]">
          We are here to help you.
        </h2>
        <div className="mb-4">
          <img src={helpGif} alt="help-gif" />
        </div>
        <button
          onClick={() => window.open("https://mail.google.com/mail/?view=cm&fs=1", "_blank")}
          className={`font-normal text-sm sm:text-base text-white border border-[#BEC1C3]text-white  bg-primary
          duration-500 px-6 rounded-[100px] text-nowrap mt-3 md:mt-[30px] h-[40px] lg:h-[60px]`}>
          Contact
        </button>
      </div>
      <section className="mt-12 sm:mt-16 md:mt-20 lg:mt-[107px] max-lg:mb-16">
        <div className=" mx-auto">
          <h2 className="text-base lg:text-xl font-semibold text-black !leading-[29px] mb-[18px] md:mb-5 lg:mb-20">
            Frequently asked questions
          </h2>
          <div className="mb-6 sm:mb-16 lg:mb-[100px]">
            <div
              className={`${
                openTab === 1
                  ? "bg-light-300 rounded-xl sm:rounded-none lg:ps-[69px] lg:pe-[45px] py-2 sm:py-6 lg:py-[51px] mb-2.5 px-2 lg:mb-10"
                  : "bg-transparent py-4 sm:py-2.5 lg:pb-10"
              }`}>
              <button
                onClick={() => toggleTab(1)}
                className="w-full text-[16px] font-semibold text-start flex items-start">
                <p className="w-full">
                  {" "}
                  1. How do I connect with other users or follow someone on the platform?
                </p>
                <span className="inline-block ms-3 mt-3">
                  <BottomArrow />
                </span>
              </button>
              {openTab === 1 && (
                <div className="px-[14px] pt-4 sm:pt-[38px] -mx-[14px]">
                  <p className="text-[14px] font-normal ">
                    To connect with other users on our platform:
                    <br />
                    1. Use the search bar at the top of the screen to find the user you want to
                    follow. <br />
                    2. Click on their name to view their profile page. <br />
                    3. Click the "Follow" button to start following them. <br />
                    4. To unfollow, go back to their profile and click the "Unfollow" button.
                  </p>
                </div>
              )}
            </div>
            <div
              className={`${
                openTab === 2
                  ? "bg-light-300 rounded-xl sm:rounded-none lg:ps-[69px] lg:pe-[45px] py-2 sm:py-6 lg:py-[51px] mb-2.5 px-2 lg:mb-10"
                  : "bg-transparent py-4 sm:py-2.5 lg:py-10"
              }`}>
              <button
                onClick={() => toggleTab(2)}
                className="w-full text-[16px] font-semibold text-start flex items-start">
                <p className="w-full">2. How do I change my account settings?</p>
                <span className="inline-block ms-3 mt-3">
                  <BottomArrow />
                </span>
              </button>
              {openTab === 2 && (
                <div className="px-[14px] max-md:py-4 pt-[38px] -mx-[14px]">
                  <p className="text-[14px] font-normal">
                    To change your account settings: <br />
                    1. Click on your profile icon in the top-right corner of the screen. <br />
                    2. Select "Settings" from the dropdown menu. <br />
                    3. Update your personal information, change your password, or manage connected
                    accounts. <br />
                    4. Save any changes before leaving the page. <br />
                  </p>
                </div>
              )}
            </div>
            <div
              className={` ${
                openTab === 3
                  ? "bg-light-300 rounded-xl sm:rounded-none lg:ps-[69px] lg:pe-[45px] py-2 sm:py-6 lg:py-[51px] mb-2.5 px-2 lg:mb-20"
                  : "bg-transparent py-4 sm:py-2.5 lg:py-10"
              }`}>
              <button
                onClick={() => toggleTab(3)}
                className="w-full text-[16px] font-semibold text-start flex items-start">
                <p className="w-full"> 3. How do I manage my privacy settings?</p>
                <span className="inline-block ms-3 mt-3">
                  <BottomArrow />
                </span>
              </button>
              {openTab === 3 && (
                <div className="px-[14px] max-md:py-4 pt-[38px] -mx-[14px]">
                  <p className="text-[14px] font-normal ">
                    To manage your privacy settings: <br />
                    1. Go to "Settings" by clicking on your profile icon and selecting "Settings."{" "}
                    <br />
                    2. Click on "Privacy and Security" from the menu. <br />
                    3. Adjust who can see your profile, posts, and personal information. <br />
                    4. Save your settings to apply changes. <br />
                  </p>
                </div>
              )}
            </div>
            <div
              className={` ${
                openTab === 4
                  ? "bg-light-300 rounded-xl sm:rounded-none lg:ps-[69px] lg:pe-[45px] py-2 sm:py-6 lg:py-[51px] mb-2.5 px-2 lg:mb-10"
                  : "bg-transparent py-4 sm:my-4  lg:my-10"
              }`}>
              <button
                onClick={() => toggleTab(4)}
                className="w-full text-[16px] font-semibold text-start flex items-start">
                <p className="w-full">4. How do I customize my notifications?</p>
                <span className="inline-block ms-3 mt-3">
                  <BottomArrow />
                </span>
              </button>
              {openTab === 4 && (
                <div className="px-[14px] max-md:py-4 pt-[38px] -mx-[14px]">
                  <p className="text-[14px] font-normal ">
                    To customize your notifications:
                    <br />
                    1. Click on your profile icon and select "Settings."
                    <br /> 2. In the left-hand menu, click on "Notifications."
                    <br /> 3. Use the toggle switches to enable or disable notifications according
                    to your preferences.
                    <br /> 4. Your changes are saved automatically.
                  </p>
                </div>
              )}
            </div>
            <div
              className={` ${
                openTab === 5
                  ? "bg-light-300 rounded-xl sm:rounded-none lg:ps-[69px] lg:pe-[45px] py-2 sm:py-6 lg:py-[51px] mb-2.5 px-2 lg:mb-10"
                  : "bg-transparent  py-4 sm:py-2.5 lg:pt-10"
              }`}>
              <button
                onClick={() => toggleTab(5)}
                className="w-full text-[16px] font-semibold text-start flex items-start">
                <p className="w-full">5. How do I report a problem or provide feedback?</p>
                <span className="inline-block ms-3 mt-3">
                  <BottomArrow />
                </span>
              </button>
              {openTab === 5 && (
                <div className="px-[14px] max-md:py-4 pt-[38px] -mx-[14px]">
                  <p className="text-[14px] font-normal ">
                    To report a problem or provide feedback:  <br />1. Navigate to the "Help" section in
                    the "Settings" menu.  <br />2. Click on "Contact Support" or "Provide Feedback." <br /> 3.
                    Fill out the form with the necessary details and submit it.
                  </p>
                </div>
              )}
            </div>
            <div
              className={` ${
                openTab === 6
                  ? "bg-light-300 rounded-xl sm:rounded-none lg:ps-[69px] lg:pe-[45px] py-2 sm:py-6 lg:py-[51px] mb-2.5 px-2 lg:mt-10"
                  : "bg-transparent py-4 sm:py-2.5 lg:mt-20"
              }`}>
              <button
                onClick={() => toggleTab(6)}
                className="w-full text-[16px] font-semibold text-start flex items-start">
                <p className="w-full">6. How do I deactivate or delete my account?</p>
                <span className="inline-block ms-3 mt-3">
                  <BottomArrow />
                </span>
              </button>
              {openTab === 6 && (
                <div className="px-[14px] max-md:py-4 pt-[38px] -mx-[14px]">
                  <p className="text-[14px] font-normal ">
                    To deactivate or delete your account:  <br />1. Go to "Settings" by clicking on your
                    profile icon and selecting "Settings." <br /> 2. Scroll down to the "Account" section. <br />
                    3. Choose to either deactivate or delete your account and follow the prompts.
                  </p>
                </div>
              )}
            </div>
            <div
              className={` ${
                openTab === 7
                  ? "bg-light-300 rounded-xl sm:rounded-none lg:ps-[69px] lg:pe-[45px] py-2 sm:py-6 lg:py-[51px] mb-2.5 px-2 lg:mt-10"
                  : "bg-transparent py-4 sm:py-2.5 lg:mt-20"
              }`}>
              <button
                onClick={() => toggleTab(7)}
                className="w-full text-[16px] font-semibold text-start flex items-start">
                <p className="w-full"> 7. How do I upload or change my profile picture?</p>
                <span className="inline-block ms-3 mt-3">
                  <BottomArrow />
                </span>
              </button>
              {openTab === 7 && (
                <div className="px-[14px] max-md:py-4 pt-[38px] -mx-[14px]">
                  <p className="text-[14px] font-normal ">
                    To upload or change your profile picture: <br /> 1. Click on your profile icon to go to
                    your profile. <br /> 2. Hover over your current picture and click "Change Picture." <br /> 3.
                    Upload a new photo from your device, adjust it as needed, and save.
                  </p>
                </div>
              )}
            </div>
            <div
              className={` ${
                openTab === 8
                  ? "bg-light-300 rounded-xl sm:rounded-none lg:ps-[69px] lg:pe-[45px] py-2 sm:py-6 lg:py-[51px] mb-2.5 px-2 lg:mt-10"
                  : "bg-transparent py-4 sm:py-2.5 lg:mt-20"
              }`}>
              <button
                onClick={() => toggleTab(8)}
                className="w-full text-[16px] font-semibold text-start flex items-start">
                <p className="w-full"> 8. How do I recover a forgotten password while logged in?</p>
                <span className="inline-block ms-3 mt-3">
                  <BottomArrow />
                </span>
              </button>
              {openTab === 8 && (
                <div className="px-[14px] max-md:py-4 pt-[38px] -mx-[14px]">
                  <p className="text-[14px] font-normal ">
                    If you're logged in but want to change your password: <br /> 1. Go to "Settings" by
                    clicking on your profile icon and selecting "Settings."  <br />2. Click on "Accounts"
                    and select "Change Password." <br /> 3. Enter your current password, then the new
                    password, and save the changes.
                  </p>
                </div>
              )}
            </div>
            <div
              className={` ${
                openTab === 9
                  ? "bg-light-300 rounded-xl sm:rounded-none lg:ps-[69px] lg:pe-[45px] py-2 sm:py-6 lg:py-[51px] mb-2.5 px-2 lg:mt-10"
                  : "bg-transparent py-4 sm:py-2.5 lg:mt-20"
              }`}>
              <button
                onClick={() => toggleTab(9)}
                className="w-full text-[16px] font-semibold text-start flex items-start">
                <p className="w-full"> 9. How do I block or report another user?</p>
                <span className="inline-block ms-3 mt-3">
                  <BottomArrow />
                </span>
              </button>
              {openTab === 9 && (
                <div className="px-[14px] max-md:py-4 pt-[38px] -mx-[14px]">
                  <p className="text-[14px] font-normal ">
                    To block or report a user:  <br />1. Go to the user’s profile.  <br />2. Click on the
                    three-dot menu.  <br />3. Select "Block" or "Report," provide necessary details, and
                    confirm your action.
                  </p>
                </div>
              )}
            </div>
            <div
              className={` ${
                openTab === 10
                  ? "bg-light-300 rounded-xl sm:rounded-none lg:ps-[69px] lg:pe-[45px] py-2 sm:py-6 lg:py-[51px] mb-2.5 px-2 lg:mt-10"
                  : "bg-transparent py-4 sm:py-2.5 lg:mt-20"
              }`}>
              <button
                onClick={() => toggleTab(10)}
                className="w-full text-[16px] font-semibold text-start flex items-start">
                <p className="w-full">10. How do I view and manage my connected accounts?</p>
                <span className="inline-block ms-3 mt-3">
                  <BottomArrow />
                </span>
              </button>
              {openTab === 10 && (
                <div className="px-[14px] max-md:py-4 pt-[38px] -mx-[14px]">
                  <p className="text-[14px] font-normal ">
                    To view and manage connected accounts:  <br />1. Go to "Settings" by clicking on your
                    profile icon. <br /> 2. Click on "Accounts" from the left-hand menu.  <br />3. Manage your
                    linked accounts, such as Google or Facebook, and save any changes.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Helps;
