import React, { useEffect, useRef, useState } from "react";
import { CrossIcon } from "../components/common/Icons";
import dropDownIcon from "../assets/icons/dropdown-arrow.svg";
import shape from "../assets/images/svg/Shape.svg";
import Btn from "../components/popup/Btn";
import { UseProfileContext } from "../components/ContextApis/ProfileContext";

function ProfessionalPopup({
  setProfessionalPopup,
  addProfessionalData,
  id,
  setProfessionalData,
  professionalData,
  handleProfessionalEdit,
}) {
  const { addProfessional, editProfessionalDetails } = UseProfileContext();
  const [errors, setErrors] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState({
    industry: false,
    companyName: false,
    Location: false,
    Designation: false,
  });
  const [userdata, setUserdata] = useState({
    industry: "",
    companyName: "",
    Designation: "",
    Location: "",
    Currently_working: "",
    from_first: "",
    from_end: "",
    till_first: "",
    till_end: "",
  });

  let data = {
    companyName: userdata.companyName,
    industry: userdata.industry,
    isCurrentlyEmployed: userdata.Currently_working === "yes" ? true : false,
    designation: userdata.Designation,
    fromDate: `01-${userdata.from_first <= 9 ? "0" : ""}${userdata.from_first}-${
      userdata.from_end
    }`,
    toDate:
      userdata.Currently_working !== "yes"
        ? `30-${userdata.till_first <= 9 ? "0" : ""}${userdata.till_first}-${userdata.till_end}`
        : "",
    location: userdata.Location,
  };
  let filterData = professionalData.filter((item, _) => item.id == id);

  // useEffect(() => {
  //   if (id !== undefined) {
  //     data["id"] = filterData[0].id;
  //   }
  // });
  useEffect(() => {
    if (id !== undefined) {
      setUserdata({
        id: filterData[0].id,
        industry: filterData[0].industry,
        companyName: filterData[0].companyName,
        Designation: filterData[0].designation,
        Location: filterData[0].location,
        Currently_working: filterData[0].isCurrentlyEmployed === false ? "No" : "yes",
        from_first: filterData[0].fromDate.split("-")[1].split("")[
          filterData[0].fromDate.split("-")[1].split("")[0] === "0" ? 1 : 0
        ],
        from_end: filterData[0].fromDate.split("-")[2],
        till_first:
          filterData[0].toDate !== null &&
          filterData[0].toDate !== "" &&
          userdata.Currently_working !== "Yes"
            ? filterData[0].toDate.split("-")[1].split("")[
                filterData[0].toDate.split("-")[1].split("")[0] === "0" ? 1 : 0
              ]
            : "",
        till_end:
          filterData[0].toDate !== null &&
          filterData[0].toDate !== "" &&
          userdata.Currently_working !== "Yes"
            ? filterData[0].toDate.split("-")[2]
            : "",
      });
    }
  }, [id, professionalData]);
  const getColorClass = (value) => (value ? "text-black" : "text-[#999898]");
  const onhandelchange = (e) => {
    const { name, value } = e.target;
    setUserdata({ ...userdata, [name]: value });
    setDropdownVisible({
      industry: name === "industry" && value.length > 0,
      companyName: name === "companyName" && value.length > 0,
      Location: name === "Location" && value.length > 0,
      Designation: name === "Designation" && value.length > 0,
    });
  };

  const industryData = ["Hindu industry", "ABC industry", "XYZ industry"];
  const companyName = ["AAkash Institute", "ABC Institute", "XYZ Institute"];
  const designationData = ["Maths", "Physics", "English"];
  const locationData = ["Delhi", "Hisar", "Hansi"];

  const validateForm = () => {
    if (!userdata.industry) return "industry is required";
    if (!userdata.companyName) return "companyName is required";
    if (!userdata.Designation) return "Designation is required";
    if (!userdata.Location) return "Location is required";
    if (!userdata.Currently_working) return "This field is required";
    if (!userdata.from_first) return "From year is required";
    if (!userdata.from_end) return "From end year is required";
    if (userdata.Currently_working === "no" && !userdata.till_first) return "Till year is required";
    if (userdata.Currently_working === "no" && !userdata.till_end)
      return "Till end year is required";

    return "";
  };

  const onhandelsubmit = (e) => {
    e.preventDefault();
    const error = validateForm();
    if (error) {
      setErrors(error);
    } else {
      if (id === undefined) {
        addProfessional(data);
        addProfessionalData(userdata);
      } else {
        editProfessionalDetails(data, id);
        let usersData = data;
        usersData["id"] = filterData[0].id;
        handleProfessionalEdit(usersData);
      }
      setProfessionalPopup(false);
      setUserdata({
        industry: "",
        companyName: "",
        Designation: "",
        Location: "",
        Currently_working: "",
        from_first: "",
        from_end: "",
        till_first: "",
        till_end: "",
      });
      setErrors("");
    }
  };

  const handleDropdownChange = (name, value) => {
    setUserdata({ ...userdata, [name]: value });
    setDropdownVisible({ ...dropdownVisible, [name]: false });
  };

  const renderDropdownOptions = (name, options) => {
    const filteredOptions = options.filter((option) =>
      option.toLowerCase().includes(userdata[name].toLowerCase())
    );

    if (filteredOptions.length === 0) {
      return <p className="text-sm py-0.5">No results found</p>;
    }

    return filteredOptions.map((option, index) => (
      <p
        key={index}
        className="text-sm py-0.5 cursor-pointer hover:bg-gray-100 rounded"
        onClick={() => handleDropdownChange(name, option)}>
        {option}
      </p>
    ));
  };

  const indRef = useRef();
  const compRef = useRef();
  const locaRef = useRef();
  const desigRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (indRef.current && !indRef.current.contains(event.target)) {
        setDropdownVisible((prev) => ({ ...prev, industry: false }));
      }
      if (compRef.current && !compRef.current.contains(event.target)) {
        setDropdownVisible((prev) => ({ ...prev, companyName: false }));
      }
      if (locaRef.current && !locaRef.current.contains(event.target)) {
        setDropdownVisible((prev) => ({ ...prev, Location: false }));
      }
      if (desigRef.current && !desigRef.current.contains(event.target)) {
        setDropdownVisible((prev) => ({ ...prev, Designation: false }));
      }
    }
    if (dropdownVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownVisible]);

  return (
    <>
      <div className="bg-white py-4 md:py-[42px] w-full sm:w-[540px] overflow-hidden sm:rounded-[40px] max-sm:h-screen sm:h-auto shadow-[0_8px_20px_0_rgba(0,0,0,0.2)] fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50">
        <div className="md:px-2 flex flex-col h-full">
          <div className=" flex gap-4 sm:justify-between items-center px-4 sm:px-5 md:px-12 pb-4 bg-white">
            <img
              className="cursor-pointer"
              width={"18px"}
              src={shape}
              onClick={() => setProfessionalPopup(false)}
              alt="shape"
            />
            <p className=" text-base font-semibold leading-5 text-black">
              {id === undefined ? "Add" : "Edit"} professional details
            </p>
            <div
              className="cursor-pointer hidden sm:block"
              onClick={() => setProfessionalPopup(false)}>
              <CrossIcon />
            </div>
          </div>
          <form className="overflow-y-auto flex-grow" onSubmit={onhandelsubmit}>
            <div className="overflow-y-auto px-4 sm:px-5 md:px-12 md:mt-6 mt-4 sm:h-[400px] sm:mx-1">
              {errors && <p className="text-red-500">{errors}</p>}
              <div className=" flex flex-col gap-6">
                <div>
                  <label
                    className=" text-base max-sm:text-sm font-medium text-black leading-5"
                    htmlFor="School">
                    Industry*
                  </label>
                  <div
                    ref={indRef}
                    className="border border-[#BEC1C3] mt-[6px] rounded-[100px] relative flex items-center ps-7 pe-4 cursor-pointer">
                    <input
                      type="text"
                      onChange={onhandelchange}
                      className="w-full outline-none h-[40px] max-sm:text-sm bg-transparent"
                      name="industry"
                      placeholder="Industry"
                      value={userdata.industry}
                      id="industry"
                    />
                    <img src={dropDownIcon} alt="dropDownIcon" />
                    <img src="" alt="" />
                    {dropdownVisible.industry && (
                      <div className="absolute top-[45px] left-0 bg-white border border-gray shadow w-full px-7 py-2 z-[1] rounded-xl dropdown">
                        {renderDropdownOptions("industry", industryData)}
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <label
                    className=" text-base font-medium text-black leading-5 max-sm:text-sm"
                    htmlFor="Institute">
                    CompanyName*
                  </label>
                  <div
                    ref={compRef}
                    className="border border-[#BEC1C3]  mt-[6px] rounded-[100px] max-sm:text-sm relative flex items-center ps-7 pe-4 cursor-pointer">
                    <input
                      type="text"
                      onChange={onhandelchange}
                      className="w-full outline-none h-[40px] max-sm:text-sm bg-transparent"
                      name="companyName"
                      id="companyName"
                      placeholder="Company"
                      value={userdata.companyName}
                    />
                    <img src={dropDownIcon} alt="dropDownIcon" />
                    {dropdownVisible.companyName && (
                      <div className="absolute top-[45px] left-0 bg-white border border-gray shadow w-full px-7 py-2 z-[1] rounded-xl dropdown">
                        {renderDropdownOptions("companyName", companyName)}
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <label
                    className=" text-base font-medium text-black leading-5 max-sm:text-sm"
                    htmlFor="Currently_studying">
                    Currently working here*
                  </label>
                  <select
                    onChange={onhandelchange}
                    className={`w-full outline-none max-sm:text-sm h-[40px] px-7 border border-[#BEC1C3]  mt-[6px] rounded-[100px] ${getColorClass(
                      userdata.Currently_working
                    )}`}
                    name="Currently_working"
                    id="Currently_working"
                    value={userdata.Currently_working}>
                    <option disabled value="">
                      Select an option
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
                <div className="w-full flex gap-3 mt">
                  <div className="w-1/2">
                    <label
                      className=" text-base font-medium text-black leading-5 max-sm:text-sm"
                      htmlFor="From_first">
                      From*
                    </label>
                    <div className="flex gap-1">
                      <div
                        className={`flex justify-between border border-[#BEC1C3] h-[40px] rounded-3xl mt-[6px] w-full ${getColorClass(
                          userdata.from_first
                        )}`}>
                        <select
                          id="month"
                          className={`font-normal text-[14px] ps-2 md:px-3 sm:text-base w-full outline-none ${getColorClass(
                            userdata.from_first
                          )}`}
                          value={userdata.from_first}
                          name="from_first"
                          onChange={onhandelchange}>
                          <option disabled value="">
                            MM
                          </option>
                          {[...Array(12)].map((_, i) => (
                            <option key={i + 1} value={i + 1}>
                              {i + 1}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div
                        className={`flex justify-between border border-[#BEC1C3] mt-[6px] h-[40px]  rounded-3xl w-full ${getColorClass(
                          userdata.from_end
                        )}`}>
                        <select
                          id="year"
                          className={`font-normal text-[14px] ps-2 md:px-3 sm:text-base w-full outline-none ${getColorClass(
                            userdata.from_end
                          )}`}
                          value={userdata.from_end}
                          name="from_end"
                          onChange={onhandelchange}>
                          <option disabled value="">
                            YYYY
                          </option>
                          {[...Array(100)].map((_, i) => {
                            const year = new Date().getFullYear() - i;
                            return (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  {userdata.Currently_working !== "yes" && (
                    <div className="w-1/2">
                      <label
                        className=" text-base font-medium text-black leading-5 max-sm:text-sm"
                        htmlFor="till_first">
                        Till*
                      </label>
                      <div className=" flex gap-1">
                        <div className=" border border-[#BEC1C3] mt-[6px] h-[40px] flex items-center rounded-[100px]  w-full">
                          <select
                            id="month"
                            className={`font-normal text-[14px] ps-2 md:px-3 sm:text-base w-full outline-none ${getColorClass(
                              userdata.till_first
                            )}`}
                            name="till_first"
                            value={userdata.till_first}
                            onChange={onhandelchange}>
                            <option disabled value="">
                              MM
                            </option>
                            {[...Array(12)].map((_, i) => (
                              <option key={i + 1} value={i + 1}>
                                {i + 1}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className=" border border-[#BEC1C3]  mt-[6px] h-[40px] rounded-[100px] flex items-center w-full max-sm:text-sm">
                          <select
                            id="year"
                            className={`font-normal text-[14px] ps-2 md:px-3 sm:text-base w-full outline-none ${getColorClass(
                              userdata.till_end
                            )}`}
                            name="till_end"
                            value={userdata.till_end}
                            onChange={onhandelchange}>
                            <option disabled value="">
                              YYYY
                            </option>
                            {[...Array(100)].map((_, i) => {
                              const year = new Date().getFullYear() - i;
                              return (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="flex flex-col gap-6">
                  <div>
                    <label
                      className=" text-base font-medium text-black leading-5 max-sm:text-sm"
                      htmlFor="Designation">
                      Designation*
                    </label>
                    <div
                      ref={desigRef}
                      className="  border border-[#BEC1C3]  mt-[6px] rounded-[100px] flex items-center ps-7 pe-4 cursor-pointer relative">
                      <input
                        type="text"
                        onChange={onhandelchange}
                        className="w-full outline-none max-sm:text-sm h-[40px]"
                        name="Designation"
                        id="Designation"
                        placeholder="Select Designation"
                        value={userdata.Designation}
                      />
                      <img src={dropDownIcon} alt="dropDownIcon" />
                      {dropdownVisible.Designation && (
                        <div className="absolute top-[45px] left-0 bg-white border border-gray shadow w-full px-7 py-2 z-[1] rounded-xl dropdown">
                          {renderDropdownOptions("Designation", designationData)}
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <label
                      className=" text-base font-medium text-black leading-5 max-sm:text-sm"
                      htmlFor="Location">
                      Location*
                    </label>
                    <div
                      ref={locaRef}
                      className="  border border-[#BEC1C3]  mt-[6px] rounded-[100px] flex items-center ps-7 pe-4 cursor-pointer relative">
                      <input
                        onChange={onhandelchange}
                        className="w-full outline-none max-sm:text-sm h-[40px]"
                        name="Location"
                        id="Location"
                        placeholder="Enter Location"
                        value={userdata.Location}
                      />
                      <img src={dropDownIcon} alt="dropDownIcon" />
                      {dropdownVisible.Location && (
                        <div className="absolute top-[45px] left-0 bg-white border border-gray shadow w-full px-7 py-2 z-[1] rounded-xl dropdown">
                          {renderDropdownOptions("Location", locationData)}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" text-center mt-6  sm:w-full lg:w-auto">
              <button type="submit">
                <Btn btnname={"Save"} />
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default ProfessionalPopup;
