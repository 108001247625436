import { createContext, useContext, useEffect, useState } from "react";
import Loader from "../Loader";
import showToast from "../ToastSucess";
import { toast } from "react-toastify";
import { getEducation, getToken } from "./TokenStore";
import { path } from "../common/Api";
import { PublicClientApplication } from "@azure/msal-browser";
const CreatePostContext = createContext();

const msalConfig = {
  auth: {
    clientId: "YOUR_CLIENT_ID",
    authority: "https://login.microsoftonline.com/YOUR_TENANT_ID",
    redirectUri: window.location.origin,
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

export function CreatePostContextProvider({ children }) {
  let token = null;
  let localTokenStore = JSON.parse(localStorage.getItem("tokens"));
  let sessionTokenStore = JSON.parse(sessionStorage.getItem("tokens"));
  if (localTokenStore === null) {
    token = sessionTokenStore;
  } else {
    token = localTokenStore;
  }
  const [loading, setLoading] = useState(false);
  const [writesupsData, setWritesupsData] = useState([]);
  const [userPosts, setUserPosts] = useState([]);
  const [notesData, setNotesData] = useState([]);
  const [searchPostCategory, setSearchPostCategory] = useState([]);
  const [profileData, setProfileData] = useState([]);
  const [oneDriveNotesData, setOneDriveNotesData] = useState([]);
  const [recommendationData, setRecommendationData] = useState([]);
  const [recomendedPeople, setRecomendedPeople] = useState([]);


    // State for image viewing
    const [isOpen, setIsOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [images, setImages] = useState([]);
  
    // Function to open the viewer with a specific set of images and index
    const openViewer = (imageArray, index) => {
      setImages(imageArray);
      setCurrentIndex(index);
      setIsOpen(true);
    };
  
    // Function to close the viewer
    const closeViewer = () => {
      setIsOpen(false);
    };
  
    // Function to go to the next image
    const goToNext = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };
  
    // Function to go to the previous image
    const goToPrev = () => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? images.length - 1 : prevIndex - 1
      );
    };

  
  // Create post
  async function addPost(postData) {
    const formData = new FormData();

    formData.append("docTitle", postData.docTitle ? postData.docTitle : "");
    formData.append("type", postData.type);
    formData.append("description", postData.description);
    if (Array.isArray(postData.files) && postData.files.length > 0) {
      postData.files.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });
    } else if (postData.files) {
      formData.append("files", postData.files);
    }
    formData.append("userMentionedIds", postData.userMentionedIds ? postData.userMentionedIds : "");
    formData.append("category", postData.category ? postData.category : "");

    setLoading(true);
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }
      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: formData,
      };

      // Make the API call
      const response = await fetch(`${path}posts/createPost`, requestOptions);

      // Check if the response is not okay
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to post");
      } else {
        showToast(data.message);
        setUserPosts([...userPosts, ...data.posts]);
      }
    } catch (error) {
      // Log the error
      console.error("Error:", error.message);
    } finally {
      setLoading(false);
    }
  }
  // Get user posts Data
  async function searchCategory(search) {
    console.log("search", search);
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }

      const requestOptions = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token?.accessToken}`,
        },
      };
      // Make the API call
      const response = await fetch(`${path}posts/category/${search}`, requestOptions);

      // Check if the response is not okay
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed To Get category");
      }
      const data = await response.json();
      setSearchPostCategory(data.data);
    } catch (error) {
      console.error("Error:", error.message);
    }
  }

  // Get user posts Data
  async function getUserPost(user) {
    setLoading(true);
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }

      const requestOptions = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token?.accessToken}`,
        },
      };
      // Make the API call
      const response = await fetch(`${path}posts/user-posts/${user}`, requestOptions);

      // Check if the response is not okay
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed To Get Education Details");
      } else {
        setLoading(false);
      }
      const data = await response.json();
      setUserPosts(data.posts);
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      setLoading(false);
    }
  }
  // Get user posts Data
  async function getUserPostById(id) {
    setLoading(true);
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }

      const requestOptions = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token?.accessToken}`,
        },
      };
      // Make the API call
      const response = await fetch(`${path}posts/postbyid/${id}`, requestOptions);

      // Check if the response is not okay
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed To Get Education Details");
      } else {
        // get other user profile data

        try {
          const response = await fetch(
            `${path}user/profile/${data.post.user?.userName}`,
            requestOptions
          );
          // Check if the response is not okay
          const errorData = await response.json();
          if (!response.ok) {
            throw new Error(errorData.message || "Failed To Get Education Details");
          } else {
            setProfileData(errorData.data);
            setLoading(false);
          }
        } catch (error) {
          console.log("Error :", error);
        }
      }
      return data.post;
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      setLoading(false);
    }
  }
  // Share post
  async function sharePost(id) {
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }

      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token?.accessToken}`,
        },
      };
      // Make the API call
      const response = await fetch(`${path}posts/incr-share-count/${id}`, requestOptions);

      // Check if the response is not okay
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed To share post");
      } else {
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  }
  // Report post
  async function repotPost(report) {
    const url = `${path}posts/report`; // Replace with your API endpoint
    if (!token.accessToken) {
      throw new Error("No access token found");
    }
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify(report),
      });
      const result = await response.json();
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      showToast(result.message);
    } catch (error) {
      console.error("Network or server error:", error);
    }
  }
  // Get Writesups Data
  async function getWritesUpsData(UniversityParams) {
    let educaDtls = getEducation();
    setLoading(true);
    let universityData = {};
    if (UniversityParams !== undefined) {
      universityData = UniversityParams;
    } else if (localStorage.getItem("universityData") !== null) {
      universityData = JSON.parse(localStorage.getItem("universityData"));
    } else {
      universityData = {
        universityName: educaDtls?.universityName
          ? educaDtls.universityName
          : "Babasaheb Ambedkar Marathwada University Aurangabad (BAMU)",
        specializationStream: educaDtls?.specializationStream
          ? educaDtls.specializationStream
          : "Chemical Engineering",
        currentSemester: educaDtls?.currentSemester ? educaDtls.currentSemester : "3",
      };
    }
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.accessToken}`,
        },
      };
      // Make the API call
      const response = await fetch(
        `${path}bystream/write-ups?university=${universityData.universityName}&stream=${universityData.specializationStream}&sem=${universityData.currentSemester}`,
        requestOptions
      );

      // Check if the response is not okay
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed To Get Education Details");
      } else {
        const data = await response.json();
        console.log("writesups", data);
        setWritesupsData(data.posts);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      setLoading(false);
    }
  }
  async function getWritesByExam(ExamParams) {
    setLoading(true);
    let examData = {};
    if (ExamParams !== undefined) {
      examData = ExamParams;
    } else {
      examData =
        JSON.parse(localStorage.getItem("exam")) !== null
          ? JSON.parse(localStorage.getItem("exam")).Exam
          : { Exam: "" };
    }
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.accessToken}`,
        },
      };
      // Make the API call
      const url = `${path}bystream/exam/write-ups?stream=${examData.Exam}`;
      const response = await fetch(url, requestOptions);

      // Check if the response is not okay
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed To Get Education Details");
      } else {
        setLoading(false);
      }
      const data = await response.json();
      setWritesupsData(data.posts);
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      setLoading(false);
    }
  }
  async function getNotes(UniversityParams) {
    let educaDtls = getEducation();
    setLoading(true);
    let universityData = {};
    if (UniversityParams !== undefined) {
      universityData = UniversityParams;
    } else if (localStorage.getItem("universityData") !== null) {
      universityData = JSON.parse(localStorage.getItem("universityData"));
    } else {
      universityData = {
        universityName: educaDtls?.universityName
          ? educaDtls.universityName
          : "Babasaheb Ambedkar Marathwada University Aurangabad (BAMU)",
        specializationStream: educaDtls?.specializationStream
          ? educaDtls.specializationStream
          : "Chemical Engineering",
        currentSemester: educaDtls?.currentSemester ? educaDtls.currentSemester : "3",
      };
    }

    try {
      const requestOptionss = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };
      // Make the API call
      const url = `${path}auth/all_contents?folderPath=Wised Content NOTES/${universityData.universityName}/${universityData.specializationStream}/${universityData.currentSemester}`;

      const responses = await fetch(url, requestOptionss);

      // Check if the response is not okay
      if (!responses.ok) {
        const errorData = await responses.json();
        throw new Error(errorData.message || "Failed To Get Education Details");
      }

      const oneDriveNotes = await responses.json();
      // setOneDriveNotesData(data.items);
      setLoading(false);

      if (!token.accessToken) {
        throw new Error("No access token found");
      }

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.accessToken}`,
        },
      };
      // Make the API call

      const response = await fetch(
        `${path}bystream/notes?university=${universityData.universityName}&stream=${universityData.specializationStream}&sem=${universityData.currentSemester}`,
        requestOptions
      );
      // Check if the response is not okay
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed To Get Education Details");
      } else {
        let oneDriveData = [];
        for (let a of oneDriveNotes.items) {
          for (let b of a.contents.items) {
            oneDriveData.push(b);
          }
        }
        setNotesData([...data.posts, ...oneDriveData]);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      setLoading(false);
    }
  }

  async function getExamNotes(ExamParams) {
    setLoading(true);
    let examData = {};
    if (ExamParams !== undefined) {
      examData = ExamParams;
    } else {
      examData =
        JSON.parse(localStorage.getItem("exam")) !== null
          ? JSON.parse(localStorage.getItem("exam")).Exam
          : { Exam: "" };
    }
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.accessToken}`,
        },
      };
      // Make the API call
      const response = await fetch(
        `${path}bystream/exam/notes?stream=${examData.Exam}`,
        requestOptions
      );

      // Check if the response is not okay
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed To Get Education Details");
      } else {
        setLoading(false);
      }
      const data = await response.json();
      setNotesData(data.posts);
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      setLoading(false);
    }
  }
  async function getRecomadationByExam(ExamParams) {
    setLoading(true);
    let examData = {};
    if (ExamParams !== undefined) {
      examData = ExamParams;
    } else {
      examData =
        JSON.parse(localStorage.getItem("exam")) !== null
          ? JSON.parse(localStorage.getItem("exam")).Exam
          : { Exam: "" };
    }
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.accessToken}`,
        },
      };
      // Make the API call
      const url = `${path}bystream/recommendationbyexam-new-user?stream=${examData.Exam}`;
      const response = await fetch(url, requestOptions);

      // Check if the response is not okay
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed To Get Education Details");
      } else {
        setLoading(false);
      }
      const data = await response.json();
      setRecommendationData(data.posts);
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      setLoading(false);
    }
  }
  async function getRecommendationNewUser(UniversityParams) {
    setLoading(true);
    let educaDtls = getEducation();
    setLoading(true);
    let universityData = {};
    if (UniversityParams !== undefined) {
      universityData = UniversityParams;
    } else if (localStorage.getItem("universityData") !== null) {
      universityData = JSON.parse(localStorage.getItem("universityData"));
    } else {
      universityData = {
        universityName: educaDtls?.universityName
          ? educaDtls.universityName
          : "Babasaheb Ambedkar Marathwada University Aurangabad (BAMU)",
        specializationStream: educaDtls?.specializationStream
          ? educaDtls.specializationStream
          : "Chemical Engineering",
        currentSemester: educaDtls?.currentSemester ? educaDtls.currentSemester : "3",
      };
    }
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.accessToken}`,
        },
      };
      // Make the API call
      const response = await fetch(
        `${path}bystream/recommendation-new-user?university=${universityData.universityName}&stream=${universityData.specializationStream}&sem=${universityData.currentSemester}`,
        requestOptions
      );

      // Check if the response is not okay
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed To Get Education Details");
      } else {
        setLoading(false);
      }
      const data = await response.json();
      setRecommendationData(data.posts);
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      setLoading(false);
    }
  }
  async function getRecommededPeople() {
    let tokens = getToken();
    setLoading(true);
    try {
      if (!tokens.accessToken) {
        throw new Error("No access token found");
      }
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokens?.accessToken}`,
        },
      };
      // Make the API call
      const response = await fetch(`${path}people/recommended`, requestOptions);

      // Check if the response is not okay
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed To Get Education Details");
      } else {
        setLoading(false);
      }
      const data = await response.json();
      setRecomendedPeople(data.data);
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      setLoading(false);
    }
  }
  async function getQuestionPaperByExam(ExamParams) {
    setLoading(true);
    let examData = {};
    if (ExamParams !== undefined) {
      examData = ExamParams;
    } else {
      examData =
        JSON.parse(localStorage.getItem("exam")) !== null
          ? JSON.parse(localStorage.getItem("exam")).Exam
          : { Exam: "" };
    }
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.accessToken}`,
        },
      };
      // Make the API call
      const response = await fetch(
        `${path}bystream/exam/question-papers?stream=${examData.Exam}`,
        requestOptions
      );

      // Check if the response is not okay
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed To Get Education Details");
      } else {
        setLoading(false);
      }
      const data = await response.json();
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      setLoading(false);
    }
  }
  async function getQuestionPaperByUniversity(UniversityParams) {
    setLoading(true);
    let educaDtls = getEducation();
    setLoading(true);
    let universityData = {};
    if (UniversityParams !== undefined) {
      universityData = UniversityParams;
    } else if (localStorage.getItem("universityData") !== null) {
      universityData = JSON.parse(localStorage.getItem("universityData"));
    } else {
      universityData = {
        universityName: educaDtls?.universityName
          ? educaDtls.universityName
          : "Babasaheb Ambedkar Marathwada University Aurangabad (BAMU)",
        specializationStream: educaDtls?.specializationStream
          ? educaDtls.specializationStream
          : "Chemical Engineering",
        currentSemester: educaDtls?.currentSemester ? educaDtls.currentSemester : "3",
      };
    }
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.accessToken}`,
        },
      };
      // Make the API call
      const response = await fetch(
        `${path}bystream/question-papers?university=${universityData.universityName}&stream=${universityData.specializationStream}&sem=${universityData.currentSemester}`,
        requestOptions
      );

      // Check if the response is not okay
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed To Get Education Details");
      } else {
        setLoading(false);
      }
      const data = await response.json();
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      setLoading(false);
    }
  }
  async function checkIsLike(id) {
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.accessToken}`,
        },
      };
      // Make the API call
      const response = await fetch(`${path}posts/like/check?postId=${id}`, requestOptions);

      // Check if the response is not okay
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed To Get Education Details");
      }
      const data = await response.json();
      return data.message;
    } catch (error) {
      console.error("Error:", error.message);
    }
  }
  async function checkIsFollow(user) {
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.accessToken}`,
        },
      };
      // Make the API call
      const response = await fetch(`${path}people/isFollowed?username=${user}`, requestOptions);

      // Check if the response is not okay
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed To Get Education Details");
      }
      const data = await response.json();
      return data.message;
    } catch (error) {
      console.error("Error:", error.message);
    }
  }
  async function deletePost(postId) {
    setLoading(true);
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }

      const requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.accessToken}`,
        },
      };
      // Make the API call
      const response = await fetch(`${path}posts/delete-post/${postId}`, requestOptions);

      // Check if the response is not okay
      const data = await response.json();
      if (!response.ok) {
        throw new Error(toast.error(data.message));
      } else {
        showToast(data.message);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      setLoading(false);
    }
  }
  const [username, setUsername] = useState(
    localStorage.getItem("userName") || sessionStorage.getItem("userName")
  );
  useEffect(() => {
    if (username) {
      getNotes();
      getQuestionPaperByUniversity();
      getRecommendationNewUser();
      getWritesUpsData();
      setUsername(localStorage.getItem("userName"));
    }
  }, [username]);

  return (
    <CreatePostContext.Provider
      value={{
        addPost,
        getWritesUpsData,
        writesupsData,
        userPosts,
        getUserPost,
        getNotes,
        notesData,
        getUserPostById,
        searchCategory,
        sharePost,
        deletePost,
        setUserPosts,
        checkIsLike,
        searchPostCategory,
        profileData,
        recommendationData,
        repotPost,
        getExamNotes,
        checkIsFollow,
        getWritesByExam,
        recomendedPeople,
        getRecommededPeople,
        getRecommendationNewUser,
        getQuestionPaperByExam,
        getQuestionPaperByUniversity,
        getRecomadationByExam,
       
        isOpen,
        currentIndex,
        images,
        openViewer,
        closeViewer,
        goToNext,
        goToPrev,
      }}>
      {loading && <Loader />} {/* Display loader when loading is true */}
      {children}
    </CreatePostContext.Provider>
  );
}

export function UseCreatePostData() {
  return useContext(CreatePostContext);
}
