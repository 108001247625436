import React, { useState, useEffect, useRef, useContext } from "react";
import { CloseIcon, NextIcon, Vertical_edit_btn } from "../common/Icons";
import { LikeIcon, DislikeIcon, ShareIcon, MaxIcon } from "../common/Icons";
import { Link, useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import { UseProfileContext } from "../ContextApis/ProfileContext";
import { UseCreatePostData } from "../ContextApis/CreatePostContext";
import showToast from "../ToastSucess";
import { RWebShare } from "react-web-share";
import * as pdfjsLib from "pdfjs-dist";
import "pdfjs-dist/build/pdf.worker.entry";
import showErrorToast from "../ToastError";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
const UserPost = ({ item, index }) => {
  const [profileImg, setprofileImg] = useState("");
  const { username } = useParams();
  const [truncatedContent, setTruncatedContent] = useState("");
  const [report, setReport] = useState(false);
  const [reportReasons, setReportReason] = useState("");
  const [reportCustomReason, setReportCustomReason] = useState("");
  const [showThreeDots, setShowThreeDots] = useState(false);
  const [block, setBlock] = useState(false);
  const { likePost, disLikePost } = UseProfileContext();
  const [like, setLike] = useState(false);
  const [fullcontent, setFullcontent] = useState("");
  const [incrimentLike, setincrimentLike] = useState(0);
  const [disLike, setDisLike] = useState(false);
  const {
    checkIsLike,
    deletePost,
    setUserPosts,
    userPosts,
    sharePost,
    repotPost,
  } = UseCreatePostData();
  const [isExpanded, setIsExpanded] = useState(false);
  const [pdf, setPdf] = useState(null);
  const [imgIndex, setImgIndex] = useState(0);
  const [images, setImages] = useState([]);
  const [writeupsImg, setWriteupsImg] = useState([]);
  const [pageRendering, setPageRendering] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  // Function to open the viewer with a specific set of images and index
  const openViewer = (imageArray, index) => {
    setImages(imageArray);
    setImgIndex(index);
    setIsOpen(true);
  };

  // Function to close the viewer
  const closeViewer = () => {
    setIsOpen(false);
  };

  const maxLength = 400; // Maximum length for truncated content

  function handleLikes(id) {
    const like = {
      postId: id,
      likeType: "LIKE",
    };
    likePost(like);
  }
  const { id } = useParams();
  function handleDisLikes(id) {
    const disLike = {
      postId: id,
      likeType: "DISLIKE",
    };
    disLikePost(disLike);
  }
  function incrimentlikes() {
    if (!like) setincrimentLike(incrimentLike + 1);
  }
  function decrimentlikes() {
    if (like) {
      setincrimentLike(incrimentLike - 1);
    }
  }
  async function likeTrue() {
    let isLike = await checkIsLike(item.id);
    if (isLike === "Liked") {
      setLike(true);
    }
  }
  function handleReportReason(e) {
    setReportReason(e.target.value);
  }

  function transformLinks(description) {
    let sanitizedDescription = DOMPurify.sanitize(description);
    let newImages;
    if (item.postType === "WRITE_UPS") {
      const parser = new DOMParser();
      const doc = parser.parseFromString(sanitizedDescription, "text/html");

      const imgElements = Array.from(doc.querySelectorAll("img"));
      newImages = imgElements.map((img) => img.src);
      imgElements.forEach((img) => img.remove());
      sanitizedDescription = doc.body.innerHTML;
    }
    // Create a temporary element to parse the sanitized HTML.
    const tempElement = document.createElement("div");
    tempElement.innerHTML = sanitizedDescription;

    // Find all <a> tags and ensure their href attributes start with http or https.
    const anchorTags = tempElement.querySelectorAll("a");
    anchorTags.forEach((anchor) => {
      const href = anchor.getAttribute("href");
      // Check if href is present and does not start with http or https.
      if (href && !/^https?:\/\//i.test(href)) {
        // Add 'http://' as the prefix to href if it is missing.
        anchor.setAttribute("href", `http://${href}`);
      }
      // Add class to open links in a new tab.
      anchor.classList.add("open-in-new-tab");
    });

    // Define a regular expression to find URLs that are NOT already inside <a> tags.
    const urlRegex = /(?<!<a[^>]*?>)(https?:\/\/[^\s<]+)(?![^<]*?<\/a>)/g;

    // Replace URLs with anchor tags and add the class.
    let modifiedDescription = tempElement.innerHTML.replace(urlRegex, (url) => {
      return `<a href="${url}" class="open-in-new-tab" rel="noopener noreferrer">${url}</a>`;
    });

    // Apply the 'target="_blank"' behavior to links with the specified class.
    const finalElement = document.createElement("div");
    finalElement.innerHTML = modifiedDescription;
    finalElement.querySelectorAll(".open-in-new-tab").forEach((link) => {
      link.setAttribute("target", "_blank");
    });

    return { sanitizedHtml: finalElement.innerHTML, images: newImages };
  }

  useEffect(() => {
    let { sanitizedHtml, images } = transformLinks(item?.description);
    // Sanitize the content
    if (item.postType === "WRITE_UPS" && images.length > 0) {
      setWriteupsImg(images);
    } else {
      setWriteupsImg([]);
    }
    setTimeout(() => {
      // Truncate the sanitized content
      const truncateText = (text, length) =>
        text && text?.length > length
          ? text.substring(0, length) + "..."
          : text;

      setTruncatedContent(truncateText(sanitizedHtml, maxLength));
    }, 1000);
  }, [item?.description, item.postType]);
  const handleToggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };
  function handleReport(e) {
    e.preventDefault();
    const reportData = {
      reportedPostId: item.id,
      reportReason: reportReasons.split(" ").join("_").toUpperCase(),
      reportDescription: reportCustomReason,
    };
    if (reportData.reportReason) {
      repotPost(reportData);
      setReport(false);
      setReportCustomReason("");
      setReportReason("");
    } else {
      showErrorToast("Please add report reason !");
    }
  }

  function handlegallreyImgages(btnName) {
    let realIndex = imgIndex + 1;
    if (
      (btnName === "next" && realIndex <= item?.awsUrl.length - 1) ||
      (btnName === "next" && realIndex <= writeupsImg.length - 1)
    ) {
      setImgIndex(imgIndex + 1);
    }
    if (btnName === "prev" && imgIndex >= 1) {
      setImgIndex(imgIndex - 1);
    }
  }

  function handlePDFImgages(btnName) {
    let realIndex = imgIndex + 1;
    if (btnName === "next" && realIndex <= images.length - 1) {
      setImgIndex(imgIndex + 1);
    }
    if (btnName === "prev" && imgIndex >= 1) {
      setImgIndex(imgIndex - 1);
    }
  }

  useEffect(() => {
    likeTrue();
  }, []);
  const hadleDeletePost = (id) => {
    const postFilter = userPosts.filter((item) => item.id !== id);
    setUserPosts(postFilter);
    deletePost(id);
  };
  useEffect(() => {
    let profileUrl = localStorage.getItem("userImg");
    setprofileImg(profileUrl);
  });

  if (showThreeDots) {
    document.body.classList.add("overflow-hidden");
  } else {
    document.body.classList.remove("overflow-hidden");
  }
  const popupRef = useRef();
  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowThreeDots(false);
        setReport(false);
      }
    }

    if (showThreeDots || report) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showThreeDots, report]);

  const [fileType, setfileType] = useState("");

  async function loadPdf(pdfLink) {
    try {
      setPageRendering(true);
      const _PDF_DOC = await pdfjsLib.getDocument({ url: pdfLink }).promise;
      setPdf(_PDF_DOC);
      setPageRendering(false);
    } catch (error) {
      console.error("Error loading PDF: ", error.message);
    }
  }
  async function renderPage() {
    if (!pdf) return;
    setPageRendering(true);
    const imagesList = [];
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const viewport = page.getViewport({ scale: 1 });
      canvas.width = viewport.width;
      canvas.height = viewport.height;
      await page.render({ canvasContext: context, viewport }).promise;
      imagesList.push(canvas.toDataURL("image/png"));
    }
    setImages(imagesList);
    setPageRendering(false);
  }

  useEffect(() => {
    if (item?.awsUrl.length > 0) {
      const fetchFileFromUrl = async (url) => {
        const fileName = url;
        setfileType(fileName.includes(".pdf") ? "pdf" : "");
        if (fileName.includes(".pdf")) {
          loadPdf(item.awsUrl[0]);
        }
      };
      fetchFileFromUrl(item.awsUrl[0]);
    }
  }, [item.awsUrl[0]]);
  useEffect(() => {
    renderPage();
  }, [pdf]);

  const timeAgo = (dateString) => {
    // Parse the date string (assuming the format is "DD-MM-YYYY | hh:mm AM/PM")
    const [datePart, timePart] = dateString.split(" | ");
    const [day, month, year] = datePart.split("-");
    const dateFormatted = `${year}-${month}-${day} ${timePart}`;

    // Convert to Date object
    const then = new Date(dateFormatted);
    const now = new Date();
    const seconds = Math.floor((now - then) / 1000);
    const interval = Math.floor(seconds / 31536000);

    if (interval > 1) {
      return `${interval} years ago`;
    }
    if (interval === 1) {
      return "1 year ago";
    }

    const months = Math.floor(seconds / 2592000);
    if (months > 1) {
      return `${months} months ago`;
    }
    if (months === 1) {
      return "1 month ago";
    }

    const days = Math.floor(seconds / 86400);
    if (days > 1) {
      return `${days} days ago`;
    }
    if (days === 1) {
      return "1 day ago";
    }

    const hours = Math.floor(seconds / 3600);
    if (hours > 1) {
      return `${hours} hours ago`;
    }
    if (hours === 1) {
      return "1 hour ago";
    }

    const minutes = Math.floor(seconds / 60);
    if (minutes > 1) {
      return `${minutes} minutes ago`;
    }
    if (minutes === 1) {
      return "1 minute ago";
    }
    return "just now";
  };

  const copyToClipboard = (url) => {
    navigator.clipboard
      .writeText("wised.in/home/PostAsViewByUser/" + url)
      .then(() => {
        showToast("URL copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy URL: ", err);
      });
  };

  const linkRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      const linkElement = document.querySelectorAll('a[rel="noopener"]');
      if (linkElement) {
        linkRef.current = linkElement;

        // Now you can manipulate the linkRef, like adding a slash at the start of the href
        for (let a of linkRef.current) {
          a.setAttribute("target", "_blank");
          a.style.color = "blue";
        }
      }
    }, 1000);
  }, [item?.description]);

  return (
    <>
      {isOpen && (
        <div   className="fixed inset-0 flex items-center justify-center z-[10000000000]">
         
         <div
            onClick={closeViewer}
            className="fixed inset-0 bg-black/80 flex items-center justify-center z-[0]"
          ></div> <button
            onClick={closeViewer}
            className="absolute top-4 right-4 text-white text-3xl font-bold"
          >
            &times;
          </button>
          <div className="relative flex items-center justify-center  max-w-[600px] max-h-[500px]">
            <TransformWrapper>
              <TransformComponent>
                <img
                  src={item.awsUrl[imgIndex]}
                  alt={`Full view ${imgIndex + 1}`}
                  className="w-full h-full p-5"
                />
              </TransformComponent>
            </TransformWrapper>
          </div>
        </div>
      )}
      {(block || showThreeDots || report) && (
        <div className="backdrop-blur-[10px] z-50 fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] h-[100vh] w-full"></div>
      )}
      <div
        className={`border-light-400 sm:p-5 px-4 py-5  border-1 inline-block ${
          index === 0 ? "border-t-0" : "border-t"
        }`}
      >
        <div className="flex justify-between items-center">
          <Link to={`/home/PostAsViewByUser/${item.id}`}>
            <div className="flex">
              <img
                src={profileImg}
                alt=""
                className="sm:max-w-[47px] sm:max-h-[47px] sm:min-w-[47px] sm:min-h-[47px] max-w-[39px] max-h-[39px] min-w-[39px] min-h-[39px] rounded-[50%]"
              />
              <div className="sm:ps-[10px] ps-[14px]">
                <p className=" xl:font-bold xl:text-base sm:font-semibold sm:text-md font-medium text-sm">
                  {item.user?.fullName ? item.user.fullName : "N/A"}
                </p>
                <div className="flex items-center">
                  {/* <p className="xl:text-base font-normal sm:text-sm text-xs pr-[6px] ">
                    {item.category}
                  </p> */}
                  <p className=" sm:text-sm font-normal text-xs text-grey">
                    {timeAgo(item.postCreatedAt)}
                  </p>
                </div>
              </div>
            </div>
          </Link>
          <div
            onClick={() => setShowThreeDots(true)}
            className="profile_vertical_icon cursor-pointer"
          >
            <Vertical_edit_btn />
          </div>
        </div>
        <div className="font-normal text-[15px] xl:pt-[23px] sm:pt-3 pt-2">
          {item?.description && (
            <div
              className="description_content"
              style={{
                display: "flex",
                flexDirection: "column",
                overflowWrap: "break-word",
              }}
              dangerouslySetInnerHTML={{
                __html: isExpanded
                  ? transformLinks(item?.description).sanitizedHtml
                  : truncatedContent,
              }}
            />
          )}

          {item?.description &&
            transformLinks(item?.description).sanitizedHtml.length > 400 && (
              <button
                onClick={handleToggleExpand}
                className="text-primary cursor-pointer md:text-base sm:text-sm text-xs ms-2 inline"
              >
                {isExpanded ? "See Less" : "See More"}
              </button>
            )}
        </div>
        <div className="relative">
          {(writeupsImg.length > 1 ||
            (item?.awsUrl && item?.awsUrl.length > 1)) && (
            <>
              <button
                style={{
                  cursor: `${
                    item?.awsUrl.length - 1 === imgIndex ? "no-drop" : "pointer"
                  }`,
                }}
                onClick={() => handlegallreyImgages("next")}
                className="bg-white flex items-center justify-center sm:w-[37px] w-[19px] sm:h-[37px] h-[19px] rounded-[50%] absolute top-1/2 right-[26px] sm:right-[38px] md:right-[59px] -translate-y-1/2 slider_btn shadow-md z-10"
              >
                <NextIcon />
              </button>
              <button
                style={{
                  cursor: `${imgIndex === 0 ? "no-drop" : "pointer"}`,
                }}
                onClick={() => handlegallreyImgages("prev")}
                className="bg-white flex items-center justify-center sm:w-[37px] w-[19px] sm:h-[37px] h-[19px] rounded-[50%] absolute top-1/2 left-[26px] sm:left-[38px] md:left-[59px] -translate-y-1/2 rotate-180 slider_btn shadow-md z-10"
              >
                <NextIcon />
              </button>
            </>
          )}
          {item.awsUrl?.length > 0 && images.length > 1 && (
            <>
              <button
                style={{
                  cursor: `${
                    images.length - 1 === imgIndex ? "no-drop" : "pointer"
                  }`,
                }}
                onClick={() => handlePDFImgages("next")}
                className="bg-white flex items-center justify-center sm:w-[37px] w-[19px] sm:h-[37px] h-[19px] rounded-[50%] absolute top-1/2 right-[26px] sm:right-[38px] md:right-[59px] -translate-y-1/2 slider_btn shadow-md z-10"
              >
                <NextIcon />
              </button>
              <button
                style={{
                  cursor: `${imgIndex === 0 ? "no-drop" : "pointer"}`,
                }}
                onClick={() => handlePDFImgages("prev")}
                className="bg-white flex items-center justify-center sm:w-[37px] w-[19px] sm:h-[37px] h-[19px] rounded-[50%] absolute top-1/2 left-[26px] sm:left-[38px] md:left-[59px] -translate-y-1/2 rotate-180 slider_btn shadow-md z-10"
              >
                <NextIcon />
              </button>
            </>
          )}
          {item.postType !== "IMAGES" &&
          item.postType !== "WRITE_UPS" &&
          item.awsUrl.length !== 0 ? (
            <div className=" rounded-[12px] sm:rounded-[31px] bg-[#F8F8F8] lg:py-6 sm:py-5 overflow-hidden mt-7">
              <div className="lg:sm:px-12 sm:px-8 px-7 max-sm:py-3 max-sm:px-4 flex justify-between sm:pb-5 pb-3">
                <p className="font-semibold sm:text-md md:text-lg text-xs">
                  Title of the document
                </p>
                <p className="font-semibold sm:text-md md:text-lg text-xs">
                  {item.postType.split("_").join(" ")}
                </p>
              </div>
              <div>
                {fileType === "pdf" ? (
                  <div className="w-full flex justify-center bg-white">
                    <div id="page-loader" hidden={!pageRendering}>
                      Loading page...
                    </div>
                    <TransformWrapper>
                      <TransformComponent>
                        <img
                          style={{ boxShadow: "0 0 17px -10px black" }}
                          className="w-full xl:max-h-[566px] sm:max-h-[400px] max-h-[280px] object-contain"
                          src={images[imgIndex]}
                          alt=""
                        />
                      </TransformComponent>
                    </TransformWrapper>
                  </div>
                ) : (
                  <TransformWrapper>
                    <TransformComponent>
                      {" "}
                      <img
                        src={item.awsUrl[imgIndex]}
                        alt=""
                        className="w-full mx-auto"
                      />
                    </TransformComponent>
                  </TransformWrapper>
                )}
              </div>
              <div className="sm:px-8 lg:px-12 px-7 max-sm:py-3 max-sm:px-5 flex justify-between sm:pt-5 lg:pt-7 pt-3">
                <p className="font-semibold sm:text-md md:text-lg text-xs">
                  {imgIndex + 1}/
                  {fileType === "pdf"
                    ? images.length
                    : item?.awsUrl && item?.awsUrl?.length}
                  {images.length == 0 && (
                    <Link
                      to={
                        fileType === "pdf"
                          ? `/showpdf/${
                              item.awsUrl[0]
                                .split(".pdf")[0]
                                .split("wised-data/")[1]
                            }.pdf=${item.id}`
                          : `/showpdf/${item.id}`
                      }
                      target="_blank"
                      className="ms-6 text-primary"
                    >
                      {imgIndex + 1 === item?.awsUrl?.length && "Open"}
                    </Link>
                  )}
                  {images.length > 0 && (
                    <Link
                      to={
                        fileType === "pdf"
                          ? `/showpdf/${
                              item.awsUrl[0]
                                .split(".pdf")[0]
                                .split("wised-data/")[1]
                            }.pdf=${item.id}`
                          : `/showpdf/${item.id}`
                      }
                      target="_blank"
                      className="ms-6 text-primary"
                    >
                      {imgIndex + 1 === images.length && "Open"}
                    </Link>
                  )}
                </p>
                <Link
                  to={
                    fileType === "pdf"
                      ? `/showpdf/${
                          item.awsUrl[0]
                            .split(".pdf")[0]
                            .split("wised-data/")[1]
                        }.pdf=${item.id}`
                      : `/showpdf/${item.id}`
                  }
                  target="_blank"
                  className="max_icon"
                >
                  <MaxIcon />
                </Link>
              </div>
            </div>
          ) : item.postType === "IMAGES" ? (
            <img
              onClick={() => openViewer(images, imgIndex)}
              src={item.awsUrl[imgIndex]}
              alt="loading..."
              className="xl:max-h-[566px] sm:max-h-[400px] max-h-[280px] rounded-[13px] mt-7 mx-auto"
            />
          ) : item.postType === "WRITE_UPS" && writeupsImg.length > 0 ? (
            <img
              src={writeupsImg[imgIndex]}
              alt="loading..."
              className="xl:max-h-[566px] sm:max-h-[400px] max-h-[280px] rounded-[13px] mt-7 mx-auto"
            />
          ) : null}
        </div>
        <div className="flex items-center xl:pt-[30px] sm:pt-6 pt-[10px]">
          <div className="sm:mr-12 mr-6 flex items-center">
            <span
              onClick={() => {
                setDisLike(false);
                setLike(!like);
                handleLikes(item.id);
                incrimentlikes();
                decrimentlikes();
              }}
              className={`inline-block sm:pr-[5px] pr-[3px] like_icon cursor-pointer ${
                like ? "like" : ""
              }`}
            >
              <LikeIcon />
            </span>
            <span className="xl:text-xxl sm:text-lg text-base font-normal">
              {Number(item.likes) + incrimentLike}
            </span>
          </div>
          <div className="sm:mr-12 mr-6 like_icon">
            <span
              onClick={() => {
                setDisLike(!disLike);
                setLike(false);
                handleDisLikes(item.id);
                decrimentlikes();
              }}
              className={`inline-block sm:pr-[5px] pr-[3px] like_icon cursor-pointer ${
                disLike ? "like" : ""
              }`}
            >
              <DislikeIcon />
            </span>
          </div>
          <div className="share_icon">
            <RWebShare
              data={{
                text: "Wised",
                url: `${item.id}`,
                title: "post",
              }}
              onClick={() => sharePost(item.id)}
            >
              <button className="share_icon cursor-pointer">
                <ShareIcon />
              </button>
            </RWebShare>
          </div>
        </div>
      </div>
      {showThreeDots && (
        <div
          ref={popupRef}
          className="max-w-[185px] sm:max-w-[320px] bg-white rounded-[16px] fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]  text-center w-full overflow-hidden z-[60] sm:h-auto sm:block flex sm:flex-row flex-col items-center sm:scroll-auto justify-center shadow-[0_8px_20px_0_rgba(0,0,0,0.2)]"
        >
          {username === undefined ? (
            <button
              onClick={() => {
                setShowThreeDots(false);
                hadleDeletePost(item.id);
              }}
              className=" font-normal sm:text-base text-xs text-red-500 py-2 inline-block cursor-pointer"
            >
              Delete
            </button>
          ) : (
            <button
              onClick={() => {
                setReport(true);
                setShowThreeDots(false);
              }}
              className="font-normal sm:text-base text-xs text-red-500 py-2 inline-block cursor-pointer "
            >
              Report
            </button>
          )}
          <hr className="w-full" />
          <button
            onClick={() => {
              setShowThreeDots(false);
              copyToClipboard(item.id);
            }}
            className=" font-normal sm:text-base text-xs  py-2 inline-block cursor-pointer"
          >
            Copy link
          </button>
          <hr className="w-full" />

          <button
            className=" font-normal sm:text-base text-xs py-2 inline-block"
            onClick={() => setShowThreeDots(false)}
          >
            Cancel
          </button>
        </div>
      )}
      {report && (
        <div
          ref={popupRef}
          className="max-w-[320px] sm:max-w-[372px] bg-white rounded-[16px] fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-center w-full overflow-hidden z-[60] sm:h-auto sm:block flex items-center sm:scroll-auto scroll-[0] sm:pt-[45px] shadow-[0_8px_20px_0_rgba(0,0,0,0.2)] xl:pb-10 sm:px-[18px] sm:pb-[45px] px-6 py-5 flex-col"
        >
          <p className=" font-medium sm:text-base text-sm text-center ">
            Report {item.user.fullName}
          </p>
          <p className="xl:text-base font-normal text-sm sm:my-6 my-4 text-center">
            Use this option to report inappropriate content or behaviour and
            prevent further interaction with this user. Your action helps
            maintain a safe environment for our community. Thank you for your
            contribution to our platform's integrity.
          </p>
          <p className="xl:text-base font-normal text-sm sm:mb-6 mb-4  text-center">
            Why are you reporting this account ?
          </p>
          <div className="flex justify-center">
            <div className="">
              <div className="flex items-center sm:mb-4 mb-[6px] ">
                <input
                  onChange={handleReportReason}
                  type="radio"
                  name="report"
                  id="spam"
                  checked={reportReasons === "Spam"}
                  value={"Spam"}
                />
                <label
                  htmlFor="spam"
                  className="sm:ms-[11px] ms-[14px] xl:text-base sm:text-sm text-xs font-normal"
                >
                  Spam
                </label>
              </div>
              <div className="flex items-center sm:mb-4 mb-[6px]">
                <input
                  onChange={handleReportReason}
                  type="radio"
                  name="report"
                  checked={reportReasons === "Offensive contents"}
                  id="Offensive"
                  value={"Offensive contents"}
                />
                <label
                  htmlFor="Offensive"
                  className="sm:ms-[11px] ms-[14px] xl:text-base sm:text-sm text-xs font-normal"
                >
                  Offensive contents
                </label>
              </div>
              <div className="flex items-center sm:mb-4 mb-[6px]">
                <input
                  onChange={handleReportReason}
                  type="radio"
                  name="report"
                  id="Fake"
                  value={"Fake account"}
                />
                <label
                  htmlFor="Fake"
                  className="sm:ms-[11px] ms-[14px] xl:text-base sm:text-sm text-xs font-normal"
                >
                  Fake account
                </label>
              </div>
              <div className="flex items-center sm:mb-6 mb-[6px]">
                <input
                  onChange={handleReportReason}
                  type="radio"
                  name="report"
                  id="Other"
                  value={"Other"}
                />
                <label
                  htmlFor="Other"
                  className="sm:ms-[11px] ms-[14px] xl:text-base sm:text-sm text-xs font-normal"
                >
                  Other
                </label>
              </div>
            </div>
          </div>
          <input
            onChange={(e) => setReportCustomReason(e.target.value)}
            type="text"
            value={reportCustomReason}
            placeholder="reason"
            className="bg-transparent border-b border-[#EEEFEF] w-[187px] max-sm:text-xs"
          />
          <div className="flex justify-center mt-6">
            <button className="inline-block me-[25px]">
              <div className="">
                <button
                  onClick={handleReport}
                  className=" text-white font-normal lg:text-base text-sm h-[40px] md:h-[50px] lg:h-[60px] w-[106px] md:w-[132px] flex items-center justify-center lg:px-10 bg-primary rounded-[100px] mx-auto sm:px-7 px-6"
                >
                  Report
                </button>
              </div>
            </button>
            <button className="inline-block">
              <div className="h-[40px]  justify-center items-center bg-white flex rounded-[100px] ">
                <button
                  type="button"
                  className=" text-black font-normal lg:text-base text-sm h-[40px] md:h-[50px] lg:h-[60px] w-[106px] md:w-[132px] flex items-center justify-center lg:px-10 bg-white rounded-[100px] mx-auto sm:px-7 px-6 border-grey border"
                  onClick={() => {
                    setReport(false);
                    setReportCustomReason("");
                    setReportReason("");
                  }}
                >
                  Cancel
                </button>
              </div>
            </button>
          </div>
          <div
            className="absolute right-[20px] top-[15px] cursor-pointer"
            onClick={() => setReport(false)}
          >
            <CloseIcon />
          </div>
        </div>
      )}
    </>
  );
};
export default UserPost;
