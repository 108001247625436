import React from "react";
import { CloseIcon } from "../components/common/Icons";
import showErrorToast from "../components/ToastError";
function Forgetpassword({ setForget, setDeletePopup, setPassword, password }) {
  return (
    <>
      <div className="max-w-[92%] md:max-w-[620px] md:rounded-[40px] rounded-[18px] bg-white  text-center sm:pb-[30px] sm:py-6 px-[24px]  py-[40px] pt-[30px] w-full shadow-[0_8px_20px_0_rgba(0,0,0,0.2)] z-[50] fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
        <div className="text-end  mt-3 me-5 hidden sm:block">
          <span
            className="cursor-pointer absolute right-[20px] top-[20px] "
            onClick={() => setForget(false)}>
            <CloseIcon />
          </span>
        </div>
        <p className="font-semibold md:text-base  text-center sm:text-sm  text-sm sm:mt-[22px] mt-6">
          For your security, please re-enter your password to continue.
        </p>
        <input
          onChange={(e) => setPassword(e.target.value)}
          placeholder="password"
          type="password"
          className="  sm:max-w-[480px] mt-6 w-full border border-1 border-[#BEC1C3] rounded-[100px] sm:mt-[22px]  md:mt-[30px] md:h-[46px] text-center sm:h-[39px] h-[40px]"
        />
        <p className="text-center md:font-semibold md:text-base sm:text-[#BEC1C3] md:mt-[30px] sm:mt-[22px] mt-6">
          Password?
        </p>

        <div
          onClick={() => {
            if (password !== "") {
              setDeletePopup(true);
              setForget(false);
            } else {
              showErrorToast("Please enter password !");
            }
          }}>
          <button
            className="font-normal mt-7 text-sm sm:text-base text-black border border-[#BEC1C3]text-white  bg-white
          duration-500 px-6 sm:px-8 rounded-[100px] text-nowrap h-[40px] lg:h-[60px]">
            Continue
          </button>
        </div>
      </div>
    </>
  );
}

export default Forgetpassword;
